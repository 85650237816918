import { useContext } from 'react';
import { Print } from '../Page/Print';
import { Admin } from '../Page/Admin';
import { Game } from '../Page/Game';
import { Home } from '../Page/Home';
import { Loign } from '../Page/Login';
import { Result } from '../Page/Result';

import { Popup } from '../Popup';
import gameContext from '../Provider/gameContext';

export function Route() {
  const { isPage, isPop } = useContext(gameContext);
  return (
    <>
      {isPage === '' && <Home />}
      {isPage === 'Login' && <Loign />}
      {isPage === 'Game' && <Game />}
      {isPage === 'Result' && <Result />}
      {isPage === 'Admin' && <Admin />}
      {isPage === 'Print' && <Print />}
      {isPop !== '' && <Popup />}
    </>
  );
}
