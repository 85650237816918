import { useEffect, useRef } from 'react';

export interface Paper {
  text1: string[];
  text2: string[];
  answer: number;
}
export interface Card {
  value: number;
  open: string;
}

export const ExPaper: Paper[] = [
  {
    text1: [
      `<ol><li><strong>ΠΟΓΠΠ ΔΧΘ.</strong></li><li><strong>ΟΔΧΧΔΔ.<strong/></li><li><strong>ΧΔΟΠΔΟΘΠ.</strong></li></ol>`,
      '<strong>ΔΔΓ : ΘΧ ΧΠ ΔΓΛΠΘ</strong>',
    ],
    text2: [
      `<ol><li><strong>ΠΟΓΓΠ ΔΧΘ.</strong></li><li><strong>ΘΔΧΧΔΔ.<strong/></li><li><strong>ΧΔΟΠΔΟΘΠ.</strong></li></ol>`,
      '<strong>ΔΔΓ : ΘΧ ΧΧ ΔΓΛΠΘ</strong>',
    ], answer: 3
  },
  {
    text1: [
      '<strong>ΛΘΟΓΘΧΠ ΧΓΠΓΟΛΠΔΧ</strong>',
      '<strong>ΛΔΘΟ ΠΘ ΓΘΛΘΘΧΘ</strong>',
      '<strong>ΠΔ - ΘΧΛΘ ΔΓΓΘ</strong>',
      '<br/>',
      '<br/>',
      '<strong>           ΓΘΛΧΠ : ΟΛΔ</strong>',
    ],
    text2: [
      '<strong>ΛΘΟΓΘΧΠ ΧΓΠΓΟΛΠΔΧ</strong>',
      '<strong>ΛΔΘΟ ΠΘ ΓΘΛΘΘΧΘ</strong>',
      '<strong>ΠΔ - ΘΧΛΘ ΔΓΓΘ</strong>',
      '<br/>',
      '<br/>',
      '<br/>',
      '<strong>           ΓΘΛΧΠ : ΟΛΔ</strong>',
    ], answer: 0
  },
  {
    text1: [
      `<h2> 3.1.2 [ΛΠΔΘ - Π]</h2>`,
      '<strong>ΓΛΧΘΠΓ ΧΟΘ Γ </strong>',
      '<strong>ΛΓΓΘ ΧΟΘ ΔΧΧΓΔ </strong>',
      `<h2> 3.1.3 [ΛΠΔΘ - ΘΔΧ]</h2>`,
    ],
    text2: [
      `<h2> 3.1.2 [ΛΠΔΘ - Π]</h2>`,
      '<strong>ΓΛΧΘΠΓ ΧΟΘ Γ </strong>',
      '<strong>ΛΓΠΘ ΧΟΘ ΔΧΧΓΔ </strong>',
      `<h2> 3.1.3 [ΛΠΔΘ - ΘΔΧ]</h2>`,
    ], answer: 1
  },
  {
    text1: [
      `<h2>ΓΓΘΧΠ ΔΧΓΠΟΓΘΠ</h2>`,
      '<strong>ΓΔΠ ΟΟΓΘΧ </strong>',
      '<strong>ΔΓΠΟΛΠ </strong>',
      `<strong>ΧΠΔΟΘΟ </strong>`,
    ],
    text2: [
      `<h2>ΓΓΘΧΠ ΔΧΓΠΟΓΘΠ</h2>`,
      '<strong>ΓΛΠ ΟΟΓΘΧ </strong>',
      '<strong>ΛΓΠΟΛΠ </strong>',
      `<strong>ΧΠΛΟΘΟ </strong>`,
    ],
    answer: 3
  },
  {
    text1: [
      `<h2> ΔΛΓΠΟΛΠΔΠΛΟΘΓ ΛΠ ΟΟΓΘΧ Λ ΘΓΠΔ ΛΠΔΓΔΧ</h2>`,

    ],
    text2: [
      `<h2> ΔΛΓΠΟΛΠΛΠΛΟΘΓ ΛΠ ΟΟΓΘΧ Λ ΘΓΠΔ ΛΠΔΓΔΧ</h2>`,
    ],
    answer: 1
  },
  {
    text1: [
      `<h2> 3. [ΛΧΠ]</h2>`,
      `<ul><li><strong>ΟΓΘΠ.</strong></li><li><strong>ΠΔΓΘ.<strong/></li><li><strong>ΔΘΠ.</strong></li></ul>`,
      `<strong>ΓΓΟ - ΧΟΧ.</strong>`,
    ],
    text2: [
      `<h2> 3. [ΛΧΠ]</h2>`,
      `<ul><li><strong>ΟΓΘ.</strong></li><li><strong>ΠΛΓΘ.<strong/></li><li><strong>ΔΘΠ.</strong></li></ul>`,
      `<strong>ΓΓΟ - ΧΟΧ.</strong>`,
    ],
    answer: 2
  },
  {
    text1: [
      '<strong>ΛΛΠΔΧ</strong>',
      '<strong>ΛΔΟ ΓΔΧ</strong>',
      '<strong>ΠΔ - ΔΓΘ</strong>',
      '<br/>',
      '<br/>',
      '<br/>',
      '<strong>           ΓΘ : ΟΛΔ</strong>',
    ],
    text2: [
      '<strong>ΛΛΠΔΧ</strong>',
      '<strong>ΛΔΟ ΓΔΧ</strong>',
      '<strong>ΠΔ - ΔΓΘ</strong>',
      '<br/>',
      '<br/>',
      '<br/>',
      '<strong>           ΓΘ : ΟΛΔ</strong>',
    ], answer: 0
  },
  {
    text1: [
      `<ol><li><strong>ΠΟΓ.</strong></li><li><strong>ΘΧΔΔ.<strong/></li><li><strong>ΟΠ.</strong></li></ol>`,
      '<strong>ΔΓ : ΓΛΠΘ</strong>',
    ],
    text2: [
      `<ol><li><strong>ΠΘΓ.</strong></li><li><strong>ΘΧΔΔ.<strong/></li><li><strong>ΟΠ.</strong></li></ol>`,
      '<strong>ΔΓ : ΠΛΠΘ</strong>',
    ],
    answer: 2
  },
  {
    text1: [
      `<ul><li><strong>ΘΧΧ.</strong></li><li><strong>Χ <u>ΔΟΓΘ</u> ΔΓΛ.<strong/></li><li><strong>ΔΘΠΛ.</strong></li></ul>`,
      '<strong>ΓΠΔ ΟΓΧΟ ΟΘΠ</strong>',
      '<strong>ΔΧ ΧΓ ΧΔΠ</strong>',
    ],
    text2: [
      `<ul><li><strong>ΘΧΧ.</strong></li><li><strong>Χ <u>ΔΟΓΘ</u> ΔΓΛ.<strong/></li><li><strong>ΔΘΠΛ.</strong></li></ul>`,
      '<strong>ΓΠΔ ΟΓΧΟ ΘΘΠ</strong>',
      '<strong>ΔΧ ΧΓ ΧΔΠ</strong>',
    ],
    answer: 1
  },
  {
    text1: [
      `<h2> 3.1.2 [ΛΠΔΘ - Π]</h2>`,
      '<strong>ΓΛ ΧΟΘ Γ </strong>',
      '<strong>ΔΧ </strong>',
      `<h2> 3.1.3 [ΛΠΔΘ - ΘΔΧ]</h2>`,
    ],
    text2: [
      `<h2> 3.1.2 [ΛΠΔΘ - Π]</h2>`,
      '<strong>ΓΛ ΧΟΘ Γ </strong>',
      '<strong>ΔΧ </strong>',
      `<h2> 3.1.3 [ΛΠΔΘ - ΘΔΧ]</h2>`,
    ],
    answer: 0
  },
  {
    text1: [
      `<h2>ΓΓΘΧΠ ΔΧΓΠ</h2>`,
      '<strong>ΓΧ </strong>',
      '<strong>ΔΓ </strong>',
      `<strong>ΧΘ </strong>`,
    ],
    text2: [
      `<h2>ΓΓΘΧΠ ΔΧΓΠ</h2>`,
      '<strong>ΓΧ </strong>',
      '<strong>ΛΓ </strong>',
      `<strong>ΧΘ </strong>`,
    ],
    answer: 1
  },
  {
    text1: [
      `<h2> 3. [ΛΧΠ]</h2>`,
      `<ul><li><strong>ΟΓΘΠ.</strong></li><li><strong>ΠΔΓΘ.<strong/></li><li><strong>ΔΘΠ.</strong></li></ul>`,
      `<strong>ΓΓΟ - ΧΟΧ.</strong>`,
    ],
    text2: [
      `<h2> 3. [ΛΧΠ]</h2>`,
      `<ul><li><strong>ΟΓΘ.</strong></li><li><strong>ΠΛΓΘ.<strong/></li><li><strong>ΔΘΠ.</strong></li></ul>`,
      `<strong>ΓΓΟ - ΧΟΧ.</strong>`,
    ],
    answer: 2
  },
  {
    text1: [
      '<strong>ΛΘΧ</strong>',
      '<br/>',
      '<strong>ΠΘΔ</strong>',
      '<br/>',
      '<strong>ΠΓ : ΘΧ</strong>',
      '<br/>',
      '<strong>ΠΔ : ΘΠΘΟ</strong>',
    ],
    text2: [
      '<strong>ΛΘΧ</strong>',
      '<br/>',
      '<strong>ΠΘΔ</strong>',
      '<br/>',
      '<strong>ΠΓ : ΘΧ</strong>',
      '<br/>',
      '<strong>ΠΔ : ΘΠΘΟ</strong>',
    ],
    answer: 0
  },
  {
    text1: [
      '<strong>ΛΘΟΓΘΧΠ ΔΓΔΠ</strong>',
      '<strong>ΠΟΓΠΠ ΔΠΛΔ</strong>',
    ],
    text2: [
      '<strong>ΛΘΟΓΘΧΠ ΔΓΔΠ</strong>',
      '<strong>ΠΟΠΓΠ ΔΠΛΔ</strong>',
    ],
    answer: 2
  },
  {
    text1: [
      `<h2> ΔΔΓ</h2>`,
      `<ul><li><strong>ΧΧΔΠΓΘ</strong></li><li><strong>ΘΔΧΧΧΟ.</strong></li></ul>`,
    ],
    text2: [
      `<h2> ΔΔΓ</h2>`,
      `<ul><li><strong>ΧΧΛΓΠΘ</strong></li><li><strong>ΘΔΧΧΧΟ.</strong></li></ul>`,
    ],
    answer: 3
  }
]


export const ExCard: Array<Card> = [
  { value: 0, open: 'false' },
  { value: 0, open: 'false' },
  { value: 2, open: 'false' },
  { value: 4, open: 'false' },
  { value: 1, open: 'false' },
  { value: 3, open: 'false' },
  { value: 6, open: 'false' },
  { value: 1, open: 'false' },
  { value: 6, open: 'false' },
  { value: 7, open: 'false' },
  { value: 4, open: 'false' },
  { value: 5, open: 'false' },
  { value: 5, open: 'false' },
  { value: 2, open: 'false' },
  { value: 3, open: 'false' },
  { value: 7, open: 'false' },
];

export const Exletter2: Array<string> = [
  '△',
  '△',
  '△',
  '△',
  '△',
  '△',
  '△',
  '△',
  '△',
  '△',
  '△',
  '△',
  '△',
  '△',
  '△',
  '△',
  '△',
  '△',
  '△',
  '△',
  '□',
  '□',
  '□',
  '□',
  '□',
  '□',
  '□',
  '□',
  '□',
  '□',
  '□',
  '□',
  '□',
  '□',
  '□',
  '□',
  '□',
  '□',
  '□',
  '□',
  '○',
  '○',
  '○',
  '○',
  '○',
  '○',
  '○',
  '○',
  '○',
  '○',
  '○',
  '○',
  '○',
  '○',
  '○',
  '○',
  '○',
  '○',
  '○',
  '○',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
  '◇',
];

export const Exletter: Array<string> = [
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',
  '△',
  '□',
  '○',

];
export const Excategory2: Array<number> = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3,
  3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 0, 0, 1, 1, 2, 2, 3, 3
];

export const Excategory1: Array<number> = [
  2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
];

export const ExPart1: Array<number> = [1, 2, 3, 4]; 
export const ExPart2: Array<number> = [3, 5, 7, 9]; 
export const ExPart3: Array<number> = [1, 4, 5, 8]; 
export const ExPart4: Array<number> = [2, 3, 5, 6]; 
export const ExPart5: Array<number> = [4, 6, 7, 9]; 


export const missileLine1 = [
  [0, 1, 0, 1],
  [0, 1, 0, 0],
  [0, 0, 1, 0],
  [0, 1, 0, 0],
  [0, 0, 0, 1],
  [0, 1, 0, 1],
  [0, 0, 0, 0],
  [0, 1, 0, 1],
  [0, 0, 1, 0],
  [0, 0, 0, 0],
  [0, 0, 0, 1],
  [0, 1, 0, 0],
  [0, 0, 0, 0],
  [0, 1, 0, 0],
  [0, 0, 0, 1],
  [0, 0, 0, 1],
  [0, 0, 1, 0],
  [0, 1, 0, 1],
  [0, 0, 1, 0],
  [0, 0, 0, 1],
  [0, 1, 0, 0],
  [0, 0, 1, 0],
  [0, 0, 0, 1],
];
export const missileLine2 = [
  [0, 0, 0, 0],
  [0, 0, 0, 1],
  [0, 0, 0, 1],
  [0, 0, 0, 1],
  [0, 1, 0, 0],
  [0, 0, 0, 0],
  [0, 0, 1, 0],
  [0, 0, 1, 0],
  [0, 0, 1, 0],
  [0, 0, 1, 0],
  [0, 0, 0, 1],
  [0, 0, 0, 1],
  [0, 1, 1, 1],
  [0, 1, 0, 0],
  [0, 1, 0, 0],
  [0, 1, 0, 0],
  [0, 1, 0, 0],
  [0, 0, 1, 0],
  [0, 1, 0, 1],
  [0, 0, 1, 0],
  [0, 0, 1, 0],
  [0, 0, 0, 1],
  [0, 0, 1, 0],
];
export const missileLine3 = [
  [0, 0, 1, 0],
  [0, 0, 1, 0],
  [0, 1, 0, 0],
  [0, 1, 0, 0],
  [0, 0, 0, 1],
  [0, 0, 1, 0],
  [0, 1, 0, 1],
  [0, 0, 0, 0],
  [0, 0, 0, 0],
  [0, 0, 1, 0],
  [0, 1, 0, 0],
  [0, 0, 0, 1],
  [0, 0, 0, 0],
  [0, 0, 0, 1],
  [0, 1, 0, 0],
  [0, 0, 1, 0],
  [0, 0, 0, 1],
  [0, 1, 0, 1],
  [0, 0, 1, 0],
  [0, 1, 0, 0],
  [0, 0, 0, 1],
  [0, 0, 1, 0],
  [0, 0, 0, 1],
];

export const isMLine1: Array<number> = [0, 0, 0, 0];

export const isMLine2: Array<number> = [0, 0, 0, 0];

export const isMLine3: Array<number> = [0, 0, 0, 0];

export const shuffledPaper = () => {
  const NewPaper = [...ExPaper].sort(() => Math.random() - 0.5);
  return NewPaper;
}

export const shuffledLetter = () => {
  const NewLetter = [...Exletter].sort(() => Math.random() - 0.5);
  return NewLetter;
};

export const shuffledLetter2 = () => {
  const NewLetter = [...Exletter2].sort(() => Math.random() - 0.5);
  return NewLetter;
};

export const shuffledCategory = () => {
  const NewCategory = [...Excategory1].sort(() => Math.random() - 0.5);
  return NewCategory;
};

export const shuffledCategory2 = () => {
  const NewCategory = [...Excategory2].sort(() => Math.random() - 0.5);
  return NewCategory;
};

export const shuffledPart = (value: number) => {
  switch (value) {
    case 0:
      const NewPart1 = [...ExPart1].sort(() => Math.random() - 0.5);
      return NewPart1;
    case 1:
      const NewPart2 = [...ExPart2].sort(() => Math.random() - 0.5);
      return NewPart2;
    case 2:
      const NewPart3 = [...ExPart3].sort(() => Math.random() - 0.5);
      return NewPart3;
    case 3:
      const NewPart4 = [...ExPart4].sort(() => Math.random() - 0.5);
      return NewPart4;
    default:
      const NewPart5 = [...ExPart5].sort(() => Math.random() - 0.5);
      return NewPart5;
  }

};

export const shuffledCard = () => {
  const NewCard = [...ExCard].sort(() => Math.random() - 0.5);
  return NewCard;
};

export const useInterval = (callback: any, delay: number) => {
  const savedCallback = useRef<any>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const executeCallback = () => {
      savedCallback.current();
    };
    console.log(delay)
    const timerId = setInterval(executeCallback, delay);

    return () => clearInterval(timerId);
  }, [delay]);
};
