import React, { useContext } from 'react';
import {
  DisplayAcc,
  DisplayFace,
  DisplayIndex1ment,
  DisplayIndex2ment,
  DisplayIndex3ment,
  DisplayIndex4ment,
  DisplayIndex1,
  DisplayIndex2,
  DisplayIndex4star,
  DisplayIndex3star,
  Displayinstar,
} from '../../Display';

import gameContext from '../../Provider/gameContext';
import '../Style.css';

export function Print() {
  const { isUser, isState, isHashtage, setPage } = useContext(gameContext);

  return (
    <div className="block-bg">
      {isUser.name !== '' && (
        <div className="block-bg-print">
          <div className="block-title-print">R E S U M E</div>
          <div className="block-header-print">
            <div className="block-header-print-imgbox">
              <div className="block-header-print-imgbox_face">
                {DisplayFace()}
                <div className="block-header-print-imgbox_acc">
                  {DisplayAcc()}
                </div>
              </div>
            </div>
            <div className="block-header-print-textbox">
              <div className="block-header-print-textbox-01">{isUser.name}</div>
              <div className="block-header-print-textbox-02">
                "{DisplayIndex2(isState.index2)}"{DisplayIndex1(isState.index1)}
              </div>

              <div className="block-header-print-textbox-03">
                weare@aroundkorea.co.kr | 070-4187-0920
              </div>
            </div>
          </div>

          <div className="block-content-print">
            <div className="block-content-print-box">
              <div className="block-content-print-title">결 과</div>

              <div className="block-content-print-content">
                <span className="block-content-print-index">
                  <div className="block-content-print-index-subject">
                    성 취 도 :
                  </div>
                  <div className="block-content-print-index-bar">
                    <div
                      className="block-content-print-index-color_bar"
                      style={{
                        width: `${isState.index1}%`,
                      }}
                    />{' '}
                  </div>{' '}
                  {isState.index1}%
                </span>
                <div className="block-content-print-indexEx">
                  - {DisplayIndex1ment(isState.index1)}
                </div>

                <span className="block-content-print-index">
                  <div className="block-content-print-index-subject">
                    스 트 레 스 :
                  </div>
                  <div className="block-content-print-index-bar">
                    <div
                      className="block-content-print-index-color_bar"
                      style={{
                        width: `${isState.index2}%`,
                      }}
                    />{' '}
                  </div>{' '}
                  {isState.index2}%
                </span>
                <div className="block-content-print-indexEx">
                  - {DisplayIndex2ment(isState.index2)}
                </div>

                <span className="block-content-print-index">
                  <div className="block-content-print-index-subject">
                    도 전 의 식 :
                  </div>
                  <div className="block-content-print-index-star">
                    {DisplayIndex3star(isState.index3)}
                  </div>
                </span>
                <div className="block-content-print-indexEx">
                  - {DisplayIndex3ment(isState.index3)}
                </div>
                <span className="block-content-print-index">
                  <div className="block-content-print-index-subject">
                    정 확 성 :
                  </div>
                  <div className="block-content-print-index-star">
                    {DisplayIndex4star(isState.index4)}
                  </div>
                </span>
                <div className="block-content-print-indexEx">
                  - {DisplayIndex4ment(isState.index4)}
                </div>
                <div className="block-content-print-instar">
                  <div className="block-content-print-instarpeed">
                    <div className="block-content-print-instarpeed-img">
                      {Displayinstar(isHashtage.tage1.split('/')[0])}
                    </div>
                    <div className="block-content-print-instarpeed-tag">
                      {isHashtage.tage1.split('/')[1]}
                    </div>
                  </div>
                  <div className="block-content-print-instarpeed">
                    <div className="block-content-print-instarpeed-img">
                      {Displayinstar(isHashtage.tage2.split('/')[0])}
                    </div>
                    <div className="block-content-print-instarpeed-tag">
                      {isHashtage.tage2.split('/')[1]}
                    </div>
                  </div>
                  <div className="block-content-print-instarpeed">
                    <div className="block-content-print-instarpeed-img">
                      {Displayinstar(isHashtage.tage3.split('/')[0])}
                    </div>
                    <div className="block-content-print-instarpeed-tag">
                      {isHashtage.tage3.split('/')[1]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <button className="btn-control-Admin" onClick={() => setPage('Admin')}>
        뒤로
      </button>
    </div>
  );
}
