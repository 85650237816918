import React, { useContext } from 'react';
import gameContext from '../../Provider/gameContext';
import '../Style.css';
export function Home() {
  const { setPage } = useContext(gameContext);
  return (
    <div className="Home_Background">
      <div className="Home_Title">
        <span style={{ fontSize: '130px' }}>IF</span>
        <span>Then...</span>
      </div>
      <button className="Start_btn" onClick={() => setPage('Login')}>
        시작하기
      </button>
    </div>
  );
}
