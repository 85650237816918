import React, { useContext, useState } from 'react';
import {
  DisplayAcc,
  DisplayFace,
  DisplayIndex,
  DisplayIndex1,
  DisplayIndex2,
} from '../../Display';

import gameContext from '../../Provider/gameContext';
import '../Style.css';

import { useInterval } from '../../Provider/gameData';

export function Result() {
  const {
    isUser,
    isState,
    setPop,
  } = useContext(gameContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isNumber, setNumber] = useState<number>(0);
  const text: string[] = [
    '결과 종합 중',
    '업무평가 중',
    '인사평가 중',
    '승진회의 중',
    '연봉협상 중',
  ];

  useInterval(() => {
    setNumber(isNumber + 1);
    if (isNumber === 3) {
      setLoading(true);
    }
  }, 2000);

  return (
    <div className="Result">
      {isNumber < 5 ? (
        <div
          className={
            !isLoading ? 'Loading_Background' : 'Loading_Background_effect'
          }
        >
          <div className="loading_bar">
            <div className="loading_bar_text">{text[isNumber]}</div>
            <div className="loading">
              <span />
              <span />
              <span />
            </div>
          </div>
        </div>
      ) : (
        <table>
          <tbody>
            <tr>
              <td rowSpan={4} className="td_rimg">
                <div className="Result_img">
                  <div className="Result_img_face">
                    {DisplayFace()}
                    <div className="Result_img_acc">{DisplayAcc()}</div>
                  </div>
                </div>
              </td>
              <td className="td_rsubject">성 명</td>
              <td colSpan={2} className="td_result">
                {isUser.name}
              </td>
            </tr>
            <tr>
              <td className="td_rsubject">직 종</td>
              <td colSpan={2} className="td_result">
                {isUser.type}
              </td>
            </tr>
            <tr>
              <td className="td_rsubject">성 취 도</td>
              <td colSpan={2} className="td_result">
                {DisplayIndex(isState.index1)}
              </td>
            </tr>
            <tr>
              <td className="td_rsubject">스 트 레 스</td>
              <td colSpan={2} className="td_result">
                {DisplayIndex(isState.index2)}
              </td>
            </tr>
            <tr>
              <td colSpan={4} className="td_rcontent">
                <p>
                  "{DisplayIndex2(isState.index2)}"{' '}
                  {DisplayIndex1(isState.index1)}
                </p>

                <p style={{ fontSize: '20px' }}>
                  * 굿즈수령을 원하시면 STAFF를 찾아주세요
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={4} className="td_rsign">
                계약자 : {isUser.name} (인)
                <button className="Login_btn1" onClick={() => setPop('alert_final')}>
                  확인
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}
