import React, { useContext, useState } from 'react';
import gameContext from '../../Provider/gameContext';

import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { listIfthenAccessKeys } from '../../graphql/queries';
import { GraphQLQuery } from '@aws-amplify/api';
import { ListIfthenAccessKeysQuery } from '../../API';
import { createIfthenAccessKey } from '../../graphql/mutations';

import awsmobile from '../../aws-exports';

import '../Style.css';
Amplify.configure(awsmobile);

export function Admin() {
  const { setPop } = useContext(gameContext);
  const [isList, setList] = useState([]);
  // const createAccessKey = async () => {
  //   const newID: string = Math.random().toString(36).substr(2, 6);
  //   const date = new Date();
  //   try {
  //     await API.graphql(
  //       graphqlOperation(createIfthenAccessKey, {
  //         input: {
  //           id: newID,
  //           activated: false,
  //           date: date.getTime(),
  //         },
  //       })
  //     );
  //     setTimeout(() => {
  //       alert('키코드 생성 완료');
  //     }, 500);
  //   } catch (error) {
  //     console.log(error);
  //     alert('키코드 생성 실패');
  //   }
  // };

  const listAccessKey = async () => {
    const datum: any = await API.graphql<
      GraphQLQuery<ListIfthenAccessKeysQuery>
    >(
      graphqlOperation(listIfthenAccessKeys, {
        filter: {
          activated: {
            eq: false,
          },
        },
      })
    );
    setList(datum.data.listIfthenAccessKeys.items);
  };

  return (
    <div className="div-bg-Admin">
      {isList.length === 0 ? (
        <>
          <button
            className="btn-control-Admin"
            onClick={() => setPop('find_user')}
          >
            결과창 조회
          </button>
          {/* <button
            className="btn-control-Admin"
            onClick={() => createAccessKey()}
          >
            key 생성
          </button> */}
          {/* <button className="btn-control-Admin" onClick={() => listAccessKey()}>
            key 리스트
          </button> */}
        </>
      ) : (
        <>
          <div className="block-list-Admin">
            {isList.length !== 0
              ? isList.map((item: any) => {
                  return (
                    <>
                      {item.id}
                      <br />
                    </>
                  );
                })
              : ''}
          </div>
          <button className="btn-control-Admin" onClick={() => setList([])}>
            뒤로가기
          </button>
        </>
      )}
    </div>
  );
}
