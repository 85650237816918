import React, { useContext, useState } from 'react';
import { DisplayAcc, DisplayFace } from '../../Display';
import gameContext, {
  Type,
  createUserData,
  getKeyData,
  getUserData,
  randomizeNumber,
  updateKeyData,
  updateUserData,
} from '../../Provider/gameContext';
import { Amplify } from 'aws-amplify';

import {
  shuffledPaper,
  shuffledCard,
  shuffledCategory,
  shuffledLetter,
  shuffledPart,
} from '../../Provider/gameData';
import awsmobile from '../../aws-exports';
import '../Style.css';

Amplify.configure(awsmobile);

export function Loign() {
  const {
    isUser,
    setHashtage,
    setUser,
    setPage,
    setPop,
    isPhase,
    setPhase,
    setPaper,
    setState,
    setCard,
    setCategorizing,
    setTyping,
    setPart,
    setDialogue
  } = useContext(gameContext);
  const [isStart, setStart] = useState<boolean>(false);
  const [isContract, setContract] = useState<number>(0);

  const handleInput = (e: React.ChangeEvent<any>) => {
    const { id, value } = e.target;
    setUser({
      ...isUser,
      [id]: value,
    });
  };

  const change = (e: React.ChangeEvent<any>) => {
    const { id, value } = e.target;
    switch (id) {
      case 'acc':
        if (value < 0) {
          setUser({
            ...isUser,
            [id]: isUser.acc - 1,
          });
        }
        if (value > 0) {
          setUser({
            ...isUser,
            [id]: isUser.acc + 1,
          });
        }
        break;
      case 'face':
        if (value < 0) {
          setUser({
            ...isUser,
            [id]: isUser.face - 1,
          });
        }
        if (value > 0) {
          setUser({
            ...isUser,
            [id]: isUser.face + 1,
          });
        }
        break;
      default:
        break;
    }
  };

  const inputCode = () => {
    if (isUser.name === '') {
      setPop('noInput');
    } else {
      findData();
    }
  };

  const findData = async () => {
    // const refinedData: any = await getUserData(isUser.name);
    // if (
    //   refinedData === null ||
    //   localStorage.getItem('ITK') !== refinedData.ifthenUserIfthenAccessKeyId
    // ) {
    //   //첫시작
    //   setContract(1);
    // } else {
    //   //입력한 닉네임과 매치되는 정보가 존재할 때
    //   if (refinedData.type === Type.default) {
    //     const key: string | null = window.localStorage.getItem('ITK');
    //     setUser({
    //       ...isUser,
    //       num: key!,
    //     });
    //     // 재시작(다회차)
    //     console.log('restart');
    //     setContract(1);
    //   } else {
    //     console.log('reconnect');
    //     // 재접속(팅김)
    //     reconnect(refinedData, false);
    //   }
    // }
    const userData: any = await getUserData(isUser.name)
    console.log(userData)
    if (userData === '-') {
      setContract(1)
    } 
    else {
      reconnect(userData)
    }

  };

  const reconnect = (data: any) => {
    switch(data.type){
      case Type.white:
        setDialogue(0)
        break;
        case Type.blue:
          setDialogue(1)
          break;
          case Type.pink:
            setDialogue(2)
            break;
    }
    setUser({
      name: data.id,
      type: data.type,
      acc: data.acc,
      face: data.face
    })
    setState({
      index1: data.accomplishmentRate,
      index2: data.stressRate,
      index3: data.challenge,
      index4: data.fail
    })
    setHashtage({
      tage1: data.tage1,
      tage2: data.tage2,
      tage3: data.tage3
    })
    switch (data.type) {
      case Type.white:
        if (data.phase === 3) setPaper(shuffledPaper());
        if (data.phase === 6) setTyping(shuffledLetter());
        break;
      case Type.blue:
        if (data.phase === 3) setCategorizing(shuffledCategory());
        if (data.phase === 6) setPart(shuffledPart(0));
        break;
      case Type.pink:
        if (data.phase === 6) setCard(shuffledCard());
        break;
      default:
        break;
    }
    setTimeout(() => {
      if(data.type===''){
        setContract(1)
      }
      else if (data.phase < 7) {
        setPage('Game');
        if (data.phase === 3 || data.phase === 6) {
          setPop('gameExplanation');
        }
        setPhase(data.phase);
      } else {
        setPop('');
        setPage('Result');
      }
    }, 1100);
  }


// const reconnect = async (refinedData: any, replay: boolean) => {
//   if (replay) {
//     await updateUserData({
//       input: {
//         id: isUser.name,
//         ifthenUserIfthenAccessKeyId: isUser.num,
//         accomplishmentRate: 20,
//         stressRate: 30,
//         type: isUser.type,
//         phase: 1,
//         acc: isUser.acc,
//         face: isUser.face,
//         tage1: '',
//         tage2: '',
//         tage3: '',
//       },
//     });
//     localStorage.setItem('ITK', isUser.num);
//     setRandom(randomizeNumber(3));
//     setStart(true);
//     setTimeout(() => {
//       setPage('Game');
//       setPhase(isPhase + 1);
//     }, 1100);
//   } else {
//     setUser({
//       name: refinedData.id,
//       num: refinedData.num,
//       type: refinedData.type,
//       acc: refinedData.acc,
//       face: refinedData.face,
//     });
//     setState({
//       index1: refinedData.accomplishmentRate,
//       index2: refinedData.stressRate,
//       index3: refinedData.challenge,
//       index4: refinedData.fail,
//     });
//     setHashtage({
//       tage1: refinedData.tage1,
//       tage2: refinedData.tage2,
//       tage3: refinedData.tage3,
//     });
//     setRandom(randomizeNumber(3));
//     switch (refinedData.type) {
//       case Type.white:
//         if (refinedData.phase === 3) setPaper(shuffledPaper());
//         if (refinedData.phase === 6) setTyping(shuffledLetter());
//         break;
//       case Type.blue:
//         if (refinedData.phase === 3) setCategorizing(shuffledCategory());
//         if (refinedData.phase === 6) setPart(shuffledPart(0));
//         break;
//       case Type.pink:
//         if (refinedData.phase === 6) setCard(shuffledCard());
//         break;
//       default:
//         break;
//     }
//     setTimeout(() => {
//       if (refinedData.phase < 7) {
//         setPage('Game');
//         if (refinedData.phase === 3 || refinedData.phase === 6) {
//           setPop('gameExplanation');
//         }
//         setPhase(refinedData.phase);
//       } else {
//         setPop('');
//         setPage('Result');
//       }
//     }, 1100);
//   }
// };

const Start = async() => {
  const userData: any = await getUserData(isUser.name)
  if(userData!=='-'){
        setStart(true);
        setTimeout(() => {
          setPage('Game');
          setPhase(isPhase + 1);
        }, 1100);
  }
  else if (
    //모든 입력값이 올바르게 들어왔을 때
    isUser.name !== '' &&
    isUser.type !== Type.default 
  ) {
      try {
        await createUserData({
          input: {
            id: isUser.name,
            accomplishmentRate: 20,
            stressRate: 30,
            type: isUser.type,
            phase: 1,
            acc: isUser.acc,
            face: isUser.face,
            tage1: '',
            tage2: '',
            tage3: '',
          },
        });
        switch(isUser.type){
          case Type.white:
            setDialogue(0)
            break;
            case Type.blue:
              setDialogue(1)
              break;
              case Type.pink:
                setDialogue(2)
                break;
        }
        setStart(true);
        setTimeout(() => {
          setPage('Game');
          setPhase(isPhase + 1);
        }, 1100);
      } catch (error) {
        console.log(error);
        setPop('error_occured');
      }
  } else {
    //입력값 자체가 올바르지 않을 경우.
    console.log('noInput');
    setPop('noInput');
  }
}

// const Start = async () => {
//   const date = new Date();
//   const userData: any = await getUserData(isUser.name);
//   if (
//     //모든 입력값이 올바르게 들어왔을 때
//     isUser.name !== '' &&
//     isUser.num !== '' &&
//     isUser.type !== Type.default &&
//     (data.ifthenAccessKeyIfthenUserId === isUser.name ||
//       data.ifthenAccessKeyIfthenUserId === null)
//   ) {
//     if (userData === '-') {
//       try {
//         console.log('?');
//         await createUserData({
//           input: {
//             id: isUser.name,
//             ifthenUserIfthenAccessKeyId: isUser.num,
//             accomplishmentRate: 20,
//             stressRate: 30,
//             type: isUser.type,
//             phase: 1,
//             acc: isUser.acc,
//             face: isUser.face,
//             tage1: '',
//             tage2: '',
//             tage3: '',
//           },
//         });
//         localStorage.setItem('ITK', isUser.num);
//         await updateKeyData({
//           input: {
//             id: isUser.num,
//             activated: true,
//             date: date.getTime(),
//             ifthenAccessKeyIfthenUserId: isUser.name,
//           },
//         });
//         setRandom(randomizeNumber(3));
//         setStart(true);
//         setTimeout(() => {
//           setPage('Game');
//           setPhase(isPhase + 1);
//         }, 1100);
//       } catch (error) {
//         console.log(error);
//         setPop('error_occured');
//       }
//     } else {
//       reconnect(userData, true);
//     }
//   } else if (data.ifthenAccessKeyIfthenUserId !== null) {
//     setPop('code_notMatch');
//   } else {
//     //입력값 자체가 올바르지 않을 경우.
//     console.log('noInput');
//     setPop('noInput');
//   }
// };

return (
  <div className={!isStart ? 'Login_Background' : 'Login_Background_effect'}>
    <div className="Login_title">등 록 카 드</div>

    <div className="Login">
      {isContract === 0 && (
        <table>
          <tr>
            <td colSpan={2} className="td_title">
              개인정보 수집 및 이용 동의
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="td_content">
              <span>HIBRIDGAMES</span>(이하 "큰 새"이라 함)과(와) <br />
              <span className="Loign_name_section">
                {isUser.name === '' && (
                  <div className="Login_alert">사용자 이름</div>
                )}
                <input
                  className="Loign_name"
                  type="text"
                  id="name"
                  value={isUser.name}
                  onChange={handleInput}
                ></input>
              </span>
              (이하 "작은 새"이라 함)은 다음과 같이 개인정보 수집 및 이용
              동의서에 동의한다.
              <p>1.개인정보의 보관 및 이용 기간</p>
              : 게임 시작 시~최대 1개월
              <br />
              <p>2. 개인정보 수집ㆍ이용 항목</p>
              : 이름, 휴대폰 번호, <br />
              <p>3. 개인정보의 수집ㆍ이용 목적</p>
              : 해당 게임 진행 및 데이터 수집에 이용. 수집한 개인정보는 본
              수집 및 이용 목적 외의 다른 목적으로 사용 되지 않음.
              <br />
              <p>4.동의거부 권리 및 불이익</p>: 해당 게임 진행 위해 필요한
              최소한의 정보에 해당, 상기 내용에 대해 본인이 동의 하지 않을
              경우 게임참여와 리워드 지급이 어려울 수 있음.
            </td>
          </tr>
          <tr>
            <td className="td_sign">
              (큰 새) : HIBRIDGAMES (인)
              <button
                className="Login_btn2"
                onClick={() => setPop('generate_Key_access')}
              >
                HI
                <br />
                BRID
              </button>
            </td>
            <td className="td_sign">
              (작은 새) : {isUser.name} (인)
              <button className="Login_btn1" onClick={() => inputCode()}>
                동의
              </button>
            </td>
          </tr>
        </table>
      )}
      {isContract === 1 && (
        <>
          <div className="Login_img">
            <div className="Login_img_facebar">
              <button
                id="face"
                value={-1}
                className="Login_img_btn_left"
                onClick={change}
              />

              <div className="Login_img_face">
                <div className="Login_img_accbar">
                  <button
                    id="acc"
                    value={-1}
                    className="Login_img_btn_left"
                    onClick={change}
                  />

                  <div className="Login_img_acc">{DisplayAcc()}</div>
                  <button
                    id="acc"
                    value={1}
                    className="Login_img_btn_right"
                    onClick={change}
                  />
                </div>
                {DisplayFace()}
              </div>
              <button
                id="face"
                value={1}
                className="Login_img_btn_right"
                onClick={change}
              />
            </div>
          </div>
          <table>
            <tr>
              <td colSpan={2} className="td_title">
                내 정보
              </td>
            </tr>
            <tr>
              <td className="td_subject">성 명</td>
              <td className="td_input">{isUser.name}</td>
            </tr>
            <tr>
              <td className="td_subject">분 류</td>
              <td className="td_input">
                <select id="type" onChange={handleInput}>
                  <option value={`${Type.default}`}>선 택</option>
                  <optgroup label="- 성 인">
                    <option value={`${Type.white}`}>{Type.white}</option>
                    <option value={`${Type.blue}`}>{Type.blue}</option>
                    <option value={`${Type.pink}`}>{Type.pink}</option>
                  </optgroup>

                  {/* <optgroup label="- 청 소 년">
                      <option value={`${Type.GE}`}>{Type.GE}</option>
                      <option value={`${Type.CIE}`}>{Type.CIE}</option>
                      <option value={`${Type.APE}`}>{Type.APE}</option>
                    </optgroup> */}
                </select>
              </td>
            </tr>
          </table>
          <button className="Login_btn" onClick={Start}>
            시작하기
          </button>
        </>
      )}
    </div>
  </div>
);
}
