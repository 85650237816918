import React, { useContext } from 'react';
import { PhaseGame } from '../../Phase/PhaseGame';
import { PhaseImg } from '../../Phase/PhaseImg';
import { PhaseSurvey } from '../../Phase/PhaseSurvey';

import gameContext from '../../Provider/gameContext';
import '../Style.css';

export function Game() {
  const { isPhase } = useContext(gameContext);
  return (
    <div className="Game_Background">
      {isPhase % 3 === 1 && <PhaseImg />}
      {isPhase % 3 === 2 && <PhaseSurvey />}
      {isPhase % 3 === 0 && <PhaseGame />}
    </div>
  );
}
