import React from 'react';
import { Paper, Card } from './gameData';
import { API } from 'aws-amplify';
import {
  GraphQLQuery,
  GraphQLResult,
  graphqlOperation,
} from '@aws-amplify/api';
import {
  CreateIfthenAccessKeyMutation,
  CreateIfthenUserMutation,
  DeleteIfthenAccessKeyMutation,
  DeleteIfthenUserMutation,
  GetIfthenAccessKeyQuery,
  GetIfthenUserQuery,
  UpdateIfthenAccessKeyMutation,
  UpdateIfthenUserMutation,
} from '../API';
import { getIfthenAccessKey, getIfthenUser } from '../graphql/queries';
import {
  createIfthenAccessKey,
  createIfthenUser,
  deleteIfthenAccessKey,
  deleteIfthenUser,
  updateIfthenAccessKey,
  updateIfthenUser,
} from '../graphql/mutations';

export enum Type {
  white = '사무직',
  blue = '생산직',
  pink = '서비스직',
  GE = '일반계열',
  CIE = '상업/공업계열',
  APE = '예체능 계열',
  default = '',
}

export interface State {
  index1: number;
  index2: number;
  index3: number;
  index4: number;
}

export interface User {
  name: string;
  // num: string;
  type: Type;
  acc: number;
  face: number;
}

export interface Answer {
  text: string;
  index1: number;
  index2: number;
  hashTage: string;
  result: string;
}

export interface HashTage {
  tage1: string;
  tage2: string;
  tage3: string;
}

export interface Survey {
  Q: string;
  A1: Answer;
  A2: Answer;
  A3: Answer;
  A4: Answer;
  A5: Answer;
}

export interface GContextProps {
  isPage: string;
  setPage: (isPage: string) => void;
  isPop: string;
  setPop: (isPop: string) => void;
  isUser: User;
  setUser: (isUser: User) => void;
  isHashtage: HashTage;
  setHashtage: (isHashtage: HashTage) => void;
  isState: State;
  setState: (isState: State) => void;
  isPhase: number;
  setPhase: (isPhase: number) => void;
  isStart: boolean;
  setStart: (isStart: boolean) => void;
  isTyping: string[];
  setTyping: (isTyping: string[]) => void;
  isCategorizing: number[];
  setCategorizing: (isCategorizing: number[]) => void;
  isPaper: Paper[];
  setPaper: (isPaper: Paper[]) => void;
  isData: string[];
  setData: (isString: string[]) => void;
  isCard: Card[];
  setCard: (isCard: Card[]) => void;
  isPart: Array<number>;
  setPart: (isPart: Array<number>) => void;
  isRound: number;
  setRound: (isRound: number) => void;
  isAnswer: Answer;
  setAnswer: (isAnswer: Answer) => void;
  isDialogue:number;
  setDialogue:(isDialogue:number)=>void;
}

const defaultState: GContextProps = {
  isPage: '',
  setPage: () => { },
  isPop: '',
  setPop: () => { },
  isUser: {
    name: '',
    // num: '',
    type: Type.default,
    acc: 0,
    face: 0,
  },
  setUser: () => { },
  isHashtage: {
    tage1: '',
    tage2: '',
    tage3: '',
  },
  setHashtage: () => { },
  isState: {
    index1: 0,
    index2: 0,
    index3: 0,
    index4: 0,
  },
  setState: () => { },
  isPhase: 0,
  setPhase: () => { },
  isStart: false,
  setStart: () => { },
  isPaper: [],
  setPaper: () => { },
  isTyping: [''],
  setTyping: () => { },
  isData: [''],
  setData: () => { },
  isCategorizing: [],
  setCategorizing: () => { },
  isCard: [],
  setCard: () => { },
  isPart: [],
  setPart: () => { },
  isRound: 0,
  setRound: () => { },
  isAnswer: {
    text: '',
    index1: 0,
    index2: 0,
    hashTage: '',
    result: ''
  },
  setAnswer: () => { },
  isDialogue:0,
  setDialogue:()=>{}
};

export const randomizeNumber = (value: number) => {
  return Math.floor(Math.random() * value);
};

export async function createUserData(input: object) {
  await API.graphql<GraphQLResult<CreateIfthenUserMutation>>(
    graphqlOperation(createIfthenUser, input)
  );
}

export async function updateUserData(input: object) {
  await API.graphql<GraphQLResult<UpdateIfthenUserMutation>>(
    graphqlOperation(updateIfthenUser, input)
  );
}

export async function deleteUserData(input: object) {
  await API.graphql<GraphQLResult<DeleteIfthenUserMutation>>(
    graphqlOperation(deleteIfthenUser, input)
  );
}

export async function getUserData(name: string) {
  const userData: any = await API.graphql<GraphQLQuery<GetIfthenUserQuery>>(
    graphqlOperation(getIfthenUser, {
      id: name,
    })
  );
  if (userData.data.getIfthenUser === null) return '-';
  else return userData.data.getIfthenUser;
}

export async function getKeyData(num: string) {
  const keyData: any = await API.graphql<GraphQLQuery<GetIfthenAccessKeyQuery>>(
    graphqlOperation(getIfthenAccessKey, {
      id: num
    })
  )
  if (keyData.data.getIfthenAccessKey === null) return '-'
  else return keyData.data.getIfthenAccessKey;
}

export async function createKeyData(input: object) {
  await API.graphql<GraphQLResult<CreateIfthenAccessKeyMutation>>(
    graphqlOperation(createIfthenAccessKey, input)
  );
}

export async function updateKeyData(input: object) {
  await API.graphql<GraphQLResult<UpdateIfthenAccessKeyMutation>>(
    graphqlOperation(updateIfthenAccessKey, input)
  );
}

export async function deleteKeyData(input: object) {
  await API.graphql<GraphQLResult<DeleteIfthenAccessKeyMutation>>(
    graphqlOperation(deleteIfthenAccessKey, input)
  );
}





export default React.createContext(defaultState);
