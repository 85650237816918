/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createIfthenUser = /* GraphQL */ `
  mutation CreateIfthenUser(
    $input: CreateIfthenUserInput!
    $condition: ModelIfthenUserConditionInput
  ) {
    createIfthenUser(input: $input, condition: $condition) {
      id
      ifthenAccessKey {
        id
        activated
        date
        ifthenUser {
          id
          accomplishmentRate
          stressRate
          challengeRate
          failRate
          type
          phase
          acc
          face
          tage1
          tage2
          tage3
          createdAt
          updatedAt
          ifthenUserIfthenAccessKeyId
          __typename
        }
        createdAt
        updatedAt
        ifthenAccessKeyIfthenUserId
        __typename
      }
      accomplishmentRate
      stressRate
      challengeRate
      failRate
      type
      phase
      acc
      face
      tage1
      tage2
      tage3
      createdAt
      updatedAt
      ifthenUserIfthenAccessKeyId
      __typename
    }
  }
`;
export const updateIfthenUser = /* GraphQL */ `
  mutation UpdateIfthenUser(
    $input: UpdateIfthenUserInput!
    $condition: ModelIfthenUserConditionInput
  ) {
    updateIfthenUser(input: $input, condition: $condition) {
      id
      ifthenAccessKey {
        id
        activated
        date
        ifthenUser {
          id
          accomplishmentRate
          stressRate
          challengeRate
          failRate
          type
          phase
          acc
          face
          tage1
          tage2
          tage3
          createdAt
          updatedAt
          ifthenUserIfthenAccessKeyId
          __typename
        }
        createdAt
        updatedAt
        ifthenAccessKeyIfthenUserId
        __typename
      }
      accomplishmentRate
      stressRate
      challengeRate
      failRate
      type
      phase
      acc
      face
      tage1
      tage2
      tage3
      createdAt
      updatedAt
      ifthenUserIfthenAccessKeyId
      __typename
    }
  }
`;
export const deleteIfthenUser = /* GraphQL */ `
  mutation DeleteIfthenUser(
    $input: DeleteIfthenUserInput!
    $condition: ModelIfthenUserConditionInput
  ) {
    deleteIfthenUser(input: $input, condition: $condition) {
      id
      ifthenAccessKey {
        id
        activated
        date
        ifthenUser {
          id
          accomplishmentRate
          stressRate
          challengeRate
          failRate
          type
          phase
          acc
          face
          tage1
          tage2
          tage3
          createdAt
          updatedAt
          ifthenUserIfthenAccessKeyId
          __typename
        }
        createdAt
        updatedAt
        ifthenAccessKeyIfthenUserId
        __typename
      }
      accomplishmentRate
      stressRate
      challengeRate
      failRate
      type
      phase
      acc
      face
      tage1
      tage2
      tage3
      createdAt
      updatedAt
      ifthenUserIfthenAccessKeyId
      __typename
    }
  }
`;
export const createIfthenAccessKey = /* GraphQL */ `
  mutation CreateIfthenAccessKey(
    $input: CreateIfthenAccessKeyInput!
    $condition: ModelIfthenAccessKeyConditionInput
  ) {
    createIfthenAccessKey(input: $input, condition: $condition) {
      id
      activated
      date
      ifthenUser {
        id
        ifthenAccessKey {
          id
          activated
          date
          createdAt
          updatedAt
          ifthenAccessKeyIfthenUserId
          __typename
        }
        accomplishmentRate
        stressRate
        challengeRate
        failRate
        type
        phase
        acc
        face
        tage1
        tage2
        tage3
        createdAt
        updatedAt
        ifthenUserIfthenAccessKeyId
        __typename
      }
      createdAt
      updatedAt
      ifthenAccessKeyIfthenUserId
      __typename
    }
  }
`;
export const updateIfthenAccessKey = /* GraphQL */ `
  mutation UpdateIfthenAccessKey(
    $input: UpdateIfthenAccessKeyInput!
    $condition: ModelIfthenAccessKeyConditionInput
  ) {
    updateIfthenAccessKey(input: $input, condition: $condition) {
      id
      activated
      date
      ifthenUser {
        id
        ifthenAccessKey {
          id
          activated
          date
          createdAt
          updatedAt
          ifthenAccessKeyIfthenUserId
          __typename
        }
        accomplishmentRate
        stressRate
        challengeRate
        failRate
        type
        phase
        acc
        face
        tage1
        tage2
        tage3
        createdAt
        updatedAt
        ifthenUserIfthenAccessKeyId
        __typename
      }
      createdAt
      updatedAt
      ifthenAccessKeyIfthenUserId
      __typename
    }
  }
`;
export const deleteIfthenAccessKey = /* GraphQL */ `
  mutation DeleteIfthenAccessKey(
    $input: DeleteIfthenAccessKeyInput!
    $condition: ModelIfthenAccessKeyConditionInput
  ) {
    deleteIfthenAccessKey(input: $input, condition: $condition) {
      id
      activated
      date
      ifthenUser {
        id
        ifthenAccessKey {
          id
          activated
          date
          createdAt
          updatedAt
          ifthenAccessKeyIfthenUserId
          __typename
        }
        accomplishmentRate
        stressRate
        challengeRate
        failRate
        type
        phase
        acc
        face
        tage1
        tage2
        tage3
        createdAt
        updatedAt
        ifthenUserIfthenAccessKeyId
        __typename
      }
      createdAt
      updatedAt
      ifthenAccessKeyIfthenUserId
      __typename
    }
  }
`;
