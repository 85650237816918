import { useContext } from 'react';
import { Game1, Game2, Game3, Game4, Game5, Game6 } from '../../Game';
import gameContext, { Type } from '../../Provider/gameContext';

export function PhaseGame() {
  const { isPhase, isUser } = useContext(gameContext);

  return (
    <>
      {isUser.type === Type.white && isPhase === 3 && <Game1 />}
      {isUser.type === Type.white && isPhase === 6 && <Game2 />}
      {isUser.type === Type.blue && isPhase === 3 && <Game3 />}
      {isUser.type === Type.blue && isPhase === 6 && <Game4 />}
      {isUser.type === Type.pink && isPhase === 3 && <Game5 />}
      {isUser.type === Type.pink && isPhase === 6 && <Game6 />}
    </>
  );
}
