import { useContext, useState } from 'react';
import gameContext, {
  Type,
  randomizeNumber,
  updateUserData,
} from '../Provider/gameContext';
import './Style.css';
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { getIfthenUser } from '../graphql/queries';
import awsmobile from '../aws-exports';
import { DisplayEx } from '../Display';

Amplify.configure(awsmobile);
export function Popup() {
  const {
    isUser,
    isPop,
    setRound,
    setState,
    setPop,
    isData,
    setStart,
    setPhase,
    isPhase,
    isState,
    isRound,
    setData,
    setPage,
    setUser,
    setHashtage,
    isAnswer
  } = useContext(gameContext);

  const [isCode, setCode] = useState('');
  const [isName, setName] = useState<string>('');

  const handleInput = (e: React.ChangeEvent<any>) => {
    const { id, value } = e.target;
    if (id === 'password') setCode(value);
    else {
      setName(value);
    }
  };
  const finish = async () => {
    setState({
      index1: 20,
      index2: 30,
      index3: 0,
      index4: 0,
    });
    setHashtage({
      tage1: '',
      tage2: '',
      tage3: '',
    });
    updateUserData({
      input: {
        id: isUser.name,
        // ifthenUserIfthenAccessKeyId: isUser.num,
        accomplishmentRate: 20,
        stressRate: 30,
        type: Type.default,
        phase: 1,
        acc: isUser.acc,
        face: isUser.face,
        tage1: '',
        tage2: '',
        tage3: '',
      },
    });
    setPop('');
    setStart(false);
    setPhase(0);
    setPage('');
  };
 

  const explanation_close = () => {
    setStart(true);
    setPop('');
  };

  const nextPhase = () => {
    setData([]);
    setPhase(isPhase + 1);
    setPop('');
  };

  const getAccessToGenerateKey = async () => {
    if (isCode === 'hibrid') {
      setTimeout(() => {
        setPop('');
        setPage('Admin');
      }, 500);
    } else {
      setPop('unvalid_code');
    }
  };

  const conitnueGame = () => {
    setState({
      ...isState,
      index3: isState.index3 + 1,
    });
    try{
    updateUserData({
      input:{
        id:isUser.name,
        challengeRate: isState.index3,
      }
    })
  }catch(error){}
    setStart(true);
    setPop('');
  };

  const stopGame = () => {
    setState({
      ...isState,
      index1: isState.index1,
      index2: isRound<=3?isState.index2 - (6 *isRound -1):isState.index2 - (6 *isRound -1),
    });
    try{
    updateUserData({
      input: {
        id: isUser.name,
        accomplishmentRate: parseFloat(isState.index1.toFixed(2)),
        stressRate: isRound<=3?parseFloat((isState.index2 - (6 *isRound -1)).toFixed(2)):parseFloat((isState.index2-(6 *isRound -1)).toFixed(2)),
        challengeRate: isState.index3,
        failRate: Math.floor(isState.index4),
        phase: isPhase + 1,
      },
    });}
    catch(error){console.log(error)}
    setRound(1);
    setStart(false);
    setPop('gameClear');
  };

  const findUser = async () => {
    try {
      const findData: any = await API.graphql(
        graphqlOperation(getIfthenUser, {
          id: isName,
        })
      );
      const refinedData: any = findData.data.getIfthenUser;

      setUser({
        name: refinedData.id,
        // num: refinedData.num,
        type: refinedData.type,
        acc: refinedData.acc,
        face: refinedData.face,
      });
      setState({
        index1: refinedData.accomplishmentRate,
        index2: refinedData.stressRate,
        index3: refinedData.challengeRate,
        index4: refinedData.failRate,
      });
      setHashtage({
        tage1: refinedData.tage1,
        tage2: refinedData.tage2,
        tage3: refinedData.tage3,
      });
      setPop('');
      setPage('Print');
    } catch (error) {
      alert('존재하지 않는 유저입니다.');
    }
  };

  const showPop = () => {
    switch (isPop) {
      case 'exist_id':
        return (
          <div className="Popup_alert">
            <div className="Popup_alert_text">이미 존재하는 닉네임입니다.</div>
            <button className="Popup_btn" onClick={() => setPop('')}>
              확 인
            </button>
          </div>
        );
      case 'noInput':
        return (
          <div className="Popup_alert">
            <div className="Popup_alert_text">입력값이 올바르지 않습니다.</div>
            <button className="Popup_btn" onClick={() => setPop('')}>
              확 인
            </button>
          </div>
        );
      case 'unvalid_code':
        return (
          <div className="Popup_alert">
            <div className="Popup_alert_text">
              승인되지 않은 식별번호입니다.
            </div>
            <button className="Popup_btn" onClick={() => setPop('')}>
              확 인
            </button>
          </div>
        );
      case 'code_notMatch':
        return (
          <div className="Popup_alert">
            <div className="Popup_alert_text">
              식별번호가 등록된 성명과 일치하지 않습니다.
            </div>
            <button className="Popup_btn" onClick={() => setPop('')}>
              확 인
            </button>
          </div>
        );
      case 'error_occured':
        return (
          <div className="Popup_alert">
            <div className="Popup_alert_text">오류가 발생했습니다.</div>
            <button className="Popup_btn" onClick={() => setPop('')}>
              확 인
            </button>
          </div>
        );
      case 'generate_Key_access':
        return (
          <div className="Popup_alert">
            <div className="Popup_alert_text">
              <input
                placeholder="관리자 모드"
                type="text"
                id="password"
                onChange={handleInput}
              />
            </div>
            <button
              className="Popup_btn"
              onClick={() => getAccessToGenerateKey()}
            >
              확 인
            </button>
          </div>
        );
      case 'surveyRespond':
        return (
          <div className="Popup_alert">
            <div className="Popup_explanation">
              <div className="Popup_explanation_title">결과 확인!</div>
              <div className="Popup_alert_text">
                {isAnswer.result}
              </div>
              <div style={{color:'green', fontSize:'1.5em'}}> 획득 성취도 : {isAnswer.index1} %</div>
              <div style={{color:'red',fontSize:'1.5em'}}> 획득 스트레스 : {isAnswer.index2*-1} %</div>
              <br />
              <button className="Popup_btn2" onClick={() => setPop('')}>
                확인
              </button>
            </div>
          </div>
        );
      case 'noAnswer':
        return (
          <div className="Popup_alert">
            <div className="Popup_alert_text">답변을 고르지 않았습니다!</div>
            <button className="Popup_btn" onClick={() => setPop('')}>
              확 인
            </button>
          </div>
        );
      case 'gameClear':
        return (
          <div className="Popup_result">
            <div className="Popup_result_title">게 임 종 료</div>
            <div className="Popup_result_content">
              <div className="Popup_result_line">
                <div className="Popup_result_subject">성 취 도:</div>
                {isState.index1 - parseFloat(isData[0]) >= 20 && (
                  <div
                    className="Popup_result_text"
                    style={{ color: 'skyblue' }}
                  >
                    +{Math.round(isState.index1 - parseFloat(isData[0]))}%
                  </div>
                )}
                {isState.index1 - parseFloat(isData[0]) >= 0 &&
                  isState.index1 - parseFloat(isData[0]) < 20 && (
                    <div
                      className="Popup_result_text"
                      style={{ color: 'green' }}
                    >
                      +{Math.round(isState.index1 - parseFloat(isData[0]))}%
                    </div>
                  )}
                {isState.index1 - parseFloat(isData[0]) < -20 && (
                  <div className="Popup_result_text" style={{ color: 'red' }}>
                    {Math.round(isState.index1 - parseFloat(isData[0]))}%
                  </div>
                )}
                {isState.index1 - parseFloat(isData[0]) < 0 &&
                  isState.index1 - parseFloat(isData[0]) > -20 && (
                    <div
                      className="Popup_result_text"
                      style={{ color: 'orange' }}
                    >
                      {Math.round(isState.index1 - parseFloat(isData[0]))}%
                    </div>
                  )}
              </div>
              <div className="Popup_result_line">
                <div className="Popup_result_subject">스 트 레 스:</div>
                {isState.index2 - parseFloat(isData[1]) >= 20 && (
                  <div className="Popup_result_text" style={{ color: 'red' }}>
                    +{Math.round(isState.index2 - parseFloat(isData[1]))}%
                  </div>
                )}
                {isState.index2 - parseFloat(isData[1]) > 0 &&
                  isState.index2 - parseFloat(isData[1]) < 20 && (
                    <div
                      className="Popup_result_text"
                      style={{ color: 'orange' }}
                    >
                      +{Math.round(isState.index2 - parseFloat(isData[1]))}%
                    </div>
                  )}
                {isState.index2 - parseFloat(isData[1]) < -20 && (
                  <div
                    className="Popup_result_text"
                    style={{ color: 'skyblue' }}
                  >
                    {Math.round(isState.index2 - parseFloat(isData[1]))}%
                  </div>
                )}
                {isState.index2 - parseFloat(isData[1]) <= 0 &&
                  isState.index2 - parseFloat(isData[1]) > -20 && (
                    <div
                      className="Popup_result_text"
                      style={{ color: 'green' }}
                    >
                      {Math.round(isState.index2 - parseFloat(isData[1]))}%
                    </div>
                  )}
              </div>
            </div>
            <button className="Popup_btn" onClick={nextPhase}>
              확 인
            </button>
          </div>
        );
      case 'gameExplanation':
        return (
    
            <div className="Popup_explanation">
              <div className="Popup_explanation_title">게 임 설 명</div>
              <div className="Popup_explanation_content">
                {DisplayEx(isUser.type, isPhase)}
              </div>
              <button className="Popup_btn" onClick={() => explanation_close()}>
                확 인
              </button>
            </div>
        
        );
      case 'game_clear':
        return (
          <div className="Popup_alert">
            <div className="Popup_explanation">
              <div className="Popup_explanation_title">게 임 종 료!</div>
              <button className="Popup_btn" onClick={() => nextPhase()}>
                확 인
              </button>
            </div>
          </div>
        );
      case 'continue_round':
        return (
          <div className="Popup_alert">
            <div className="Popup_explanation">
              <div className="Popup_explanation_title">라운드 종료!</div>
              <div className="Popup_alert_text">
                업무 하나를 끝냈습니다. 당신은 선택의 기로에 놓여있습니다...
              </div>
              <div style={{color:'green', fontSize:'1.5em'}}> 성취도 : {Math.floor(isState.index1)} %</div>
              <div style={{color:'red',fontSize:'1.5em'}}> 스트레스 : {Math.floor(isState.index2)} %</div>

              <div className="Popup_alert_text">
                업무를 진행하면{' '}
                <span style={{ color: 'green', fontWeight: 'bolder' }}>
                  성취도와 게임 종료시 스트레스 회복을 높여
                </span>
                주지만 라운드가 늘어날수록{' '}
                <span style={{ color: 'red', fontWeight: 'bolder' }}>
                  클리어가 어려워집니다.
                </span>
              </div>
              <br />
              <button className="Popup_btn2" onClick={() => stopGame()}>
                적당히 휴식을 취하겠습니다.<div style={{color:'green'}}>{`(스트레스 ${6*isRound-1}% 회복)`}</div>
              </button>
              <button className="Popup_btn2" onClick={() => conitnueGame()}>
                업무를 더 진행하겠습니다.<div style={{color:'green'}}>{'(성취도를 최대 약 8% 올릴 수 있음)'}</div>
              </button>
            </div>
          </div>
        );
      case 'find_user':
        return (
          <div className="Popup_alert">
            <div className="Popup_alert_text">
              <input
                placeholder="사용자 이름"
                type="text"
                id="isName"
                onChange={handleInput}
              />
            </div>
            <button className="Popup_btn" onClick={() => setPop('')}>
              뒤로
            </button>
            <button className="Popup_btn" onClick={() => findUser()}>
              확 인
            </button>
          </div>
        );
        case 'alert_final':
          return (
            <div className="Popup_alert">
              <div className="Popup_explanation">
                <div className="Popup_explanation_title">주의하세요!</div>
                <div className="Popup_alert_text">
                부스에 있는 STAFF에게 확인을 받지 않으면 결과지를 받을 수 없습니다. 물론 언제든 다시 게임을 해서 더 좋은 결과를 들고오셔도 됩니다!
              </div>
                  
                <button className="Popup_btn" onClick={() => finish()}>
                  결과지는 다음에 받겠습니다
                </button>
              </div>
            </div>
          );
      default:
        return <></>;
    }
  };

  return <div className="Popup_back">{showPop()}</div>;
}
