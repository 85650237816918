import React, { useState, useEffect } from 'react';
import GContext, {
  Answer,
  GContextProps,
  HashTage,
  Type,
} from './Provider/gameContext';
import './App.css';
import { Card, Paper } from './Provider/gameData';
import { Route } from './Route';

function App() {
  const [isMobileWidth, setMobileWidth] = useState(0);
  const [isMobileHeight, setMobileHeight] = useState(0);
  const [isPage, setPage] = useState('');
  const [isPop, setPop] = useState('');
  const [isStart, setStart] = useState(false);
  const [isRound, setRound] = useState(1);
  const [isUser, setUser] = useState({
    name: '',
    // num: '',
    type: Type.default,
    acc: 5,
    face: 5,
  });
  const [isState, setState] = useState({
    index1: 25,
    index2: 25,
    index3: 0,
    index4: 0,
  });

  const [isHashtage, setHashtage] = useState<HashTage>({
    tage1: '',
    tage2: '',
    tage3: '',
  });

  const [isPhase, setPhase] = useState(0);
  const [isPaper, setPaper] = useState<Paper[]>([]);
  const [isTyping, setTyping] = useState(['']);
  const [isData, setData] = useState(['']);
  const [isCategorizing, setCategorizing] = useState([0]);
  const [isCard, setCard] = useState<Card[]>([]);
  const [isPart, setPart] = useState<Array<number>>([]);
  const [isAnswer, setAnswer] = useState<Answer>({
    text: '',
    index1: 0,
    index2: 0,
    hashTage: '',
    result: '',
  });
  const [isDialogue,setDialogue]=useState<number>(0)
  const GContextValue: GContextProps = {
    isPage,
    setPage,
    isPop,
    setPop,
    isUser,
    setUser,
    isHashtage,
    setHashtage,
    isState,
    setState,
    isPhase,
    setPhase,
    isStart,
    setStart,
    isPaper,
    setPaper,
    isData,
    setData,
    isTyping,
    setTyping,
    isCategorizing,
    setCategorizing,
    isCard,
    setCard,
    isPart,
    setPart,
    isRound,
    setRound,
    isAnswer,
    setAnswer,
    isDialogue,
    setDialogue
  };

  useEffect(() => {
    setMobileWidth(window.innerWidth);
    setMobileHeight(window.innerHeight);
  }, []);

  return (
    <GContext.Provider value={GContextValue}>
      <div
        className="wrapper"
        style={{
          width: `${isMobileWidth}px`,
          height: `${isMobileHeight}px`,
        }}
      >
        <Route />
      </div>
    </GContext.Provider>
  );
}
export default App;
