import { Survey } from './gameContext';

export const morningQuestion: Survey[] = [
  {
    Q: '09:00 출근! 아침을 상쾌하게 시작하려던 나는 엘리베이터에서 부장님을 만났다. "자네 출근 시간이 늦구만." 부장님의 한 마디. 나의 선택은?',
    A1: {
      text: '계약서에는 9시 출근이라고 명시되어있습니다. 부장님.',
      index1: -5,
      index2: 3,
      hashTage: '1/#원칙주의자 #T형인간 #구식문화',
      result:'9시까지 출근이라 시간 맞춰 출근했는데 뭐가 문제야? 나는 잘못한 게 없다. 하지만 생각해보면 굳이 이래야했을까?'
    },
    A2: {
      text: '부장님도 지금 출근하시잖아요.',
      index1: -7,
      index2: 5,
      hashTage: '1/#너나잘해 #할말은하자 #당돌한MZ',
      result:'본인도 늦으면서 왜 나한테만 그래? 할 말은 해야겠다. 하지만 허허 웃는 부장님의 눈빛에는 싸늘함만이 감돈다.'
    },
    A3: {
      text: '에어팟을 끼고 있었다. 난 아무튼 못 들었다.',
      index1: -10,
      index2: -5,
      hashTage: '1/#MZ #노이즈캔슬링 #모르쇠',
      result:'못 들은 척 하고 있는데 선배가 내 이름을 불러서 나도 모르게 대답해버렸다. 이런... 큰일났는데...?'
    },
    A4: {
      text: '다음날에 새벽에 출근한다. 그래, 이런 걸 원한거지?',
      index1: 10,
      index2: -7,
      hashTage: '1/#소심한복수 #극단적 #맑눈광 ',
      result:'새벽에 출근한 건 좋았지만 되게 피곤하다. 부장님이 그런 날 보고 씩 웃으며 말했다. 앞으로도 이렇게만 해주게.'
    },
    A5: {
      text: '죄송합니다. 오늘따라 차가 막혀서 늦었습니다. 하하.',
      index1: 0,
      index2: 0,
      hashTage: '1/#철판깔기왕 #사회생활 #변명 ',
      result:'허허. 거 일찍일찍 다닙시다. 미리 와서 일할 준비도 하고 그래야지. 부장님의 잔소리가 들려온다. 하지만 이 정도면 뭐... '
    },
  },
  {
    Q: '업무 도중 선배가 도와달라면서 일거리를 준다. 심지어 어떻게 해야 하는지 잘 모르는 일인데...?',
    A1: {
      text: '일단 할 수 있는만큼 해본다. 인터넷 검색만이 유일한 희망이다.',
      index1: 0,
      index2: -5,
      hashTage: '2/#구글은신이야 #노력주의자 #성장형',
      result:'그래. 까라면 까야지. 나름대로 열심해했지만 결과물을 본 선배는 불만족스러운 표정을 지었다. 하, 그럼 너가 하던가, 진짜.'
    },
    A2: {
      text: '상사한테 이 일을 어떻게 해야 하는 건지 물어본다.',
      index1: 5,
      index2: 3,
      hashTage: '2/#물음표살인마 #질문형 #여기가학교냐 ',
      result:'상사에게 물어보니 귀찮아하면서도 하나하나 알려줬다. 그러면서 이거 누가 시킨거냐고 물어본다. 오, 천사이신데?'
    },
    A3: {
      text: '일을 준 선배에게 도와주고 싶지만 이거 처음 해보는 일이라고 얘기한다.',
      index1: 0,
      index2: 5,
      hashTage: '2/#솔직담백형 #말하는감자 #조언요구형',
      result:'그래, 너가 할 수 있을 리가 없지. 미안하다라고 하는 선배의 말이 귓구멍에 꽂힌다. 저거저거 말투가 아주 짜증나네.'
    },
    A4: {
      text: '지금 해야할 일이 많아서 도와주기는 힘들 것 같다고 한다.',
      index1: -3,
      index2: 3,
      hashTage: '2/#업무거절형 #난못해 #니할일은니가하라거 ',
      result:'선배가 한숨을 쉬더니 본인 자리로 돌아갔다. 내 일도 많은데 왜 나한테 시키는 거야? '
    },
    A5: {
      text: '대충해서 준다. 어떻게 될 지는 모르겠지만 말이다.',
      index1: -3,
      index2: -5,
      hashTage: '2/#선처리후뚜맞 #너가안알려줬잖아 #책임은니가져',
      result:'아니 뭐한거야? 누가 일처리를 이따구로 해? 장난해? 선배가 많이 화난 거 같다. 그러게, 그걸 왜 나한테... '
    },
  },
  {
    Q: '전날의 회식 탓일까. 오늘따라 너무 졸리다. 난 어떻게 해야할까.',
    A1: {
      text: '잠 오는 걸 어쩌겠냐. 내 자리에서 편하게 잔다.',
      index1: -7,
      index2: 10,
      hashTage: '3/#눈치상실형 #건들지마셈 #회사에서꿀잠 ',
      result:'그냥 자야지. 일단 잠이 오는데 어쩔 수 없지. 뭐 끝이 안 좋을 거 같긴 한데...'
    },
    A2: {
      text: '탕비실에 있는 커피를 다 털어서라도 잠을 깬다.',
      index1: 5,
      index2: -5,
      hashTage: '3/#카페인의힘 #노력아주칭찬해 #무지출챌린지 ',
      result:'그래. 졸려도 참고 일은 해야지. 카페인만 있으면 난 천하무적이다. 잠 깨는 효과는 좋았지만 결국 화장실에 계속 들락거리는 것은 어쩔 수가 없었다.'
    },
    A3: {
      text: '화장실에서 몰래 잔다. 화장실이 깨끗해서 다행이야.',
      index1: 0,
      index2: 5,
      hashTage: '3/#화장실노숙 #인간존엄성 #이렇게까지...',
      result: '그냥 자리에서 자기는 쫌... 이렇게까지 해야할까 싶지만 졸린 걸 어떡해. 인간의 존엄성에 관해 진지한 의문이 들지만 이내 쏟아지는 잠에 그런 생각들은 의식의 저 너머로 사라졌다.'
    },
    A4: {
      text: '산책을 하면서 재빠르게 캐시워크를 하고 온다.',
      index1: -3,
      index2: 7,
      hashTage: '3/#티끌모아태산 #진정한직장인 #앱테크',
      result:'티끌모아 태산이라고 했다. 틈틈히 벌 수 있을 때는 벌어야지. 물론 갔다와서 동료들의 쏟아지는 눈빛은 어쩔 수 없었지만.'
    },
    A5: {
      text: '정신이 번쩍들게 1024연차 양산형 모바일 게임을 한다.',
      index1: -12,
      index2: 7,
      hashTage: '3/#모바일게임러버 #확률형가챠 #서버비를벌자',
      result:'히히 재밌다. 역시 이럴 때 하는 게임이 최고다. 게다가 이번에는 운도 좋게 성능 좋은 장비도 많이 뽑았다. 하지만 우리 모두 알잖아. 회사에서 이런 짓은 끝이 좋지 않을거란걸. '
    },
  },
];

export const afternoonQuestion: Survey[] = [
  {
    Q: '오늘 점심은 시켜먹자고 하는 부장님. 나보고 메뉴를 고르라고 하신다. ',
    A1: {
      text: '내가 먹고 싶은 메뉴로 주문한다.',
      index1: -3,
      index2: 10,
      hashTage: '4/#싫으면니가시켜 #내맘대로 #센스것',
      result:'이럴 때 내가 먹고 싶은 거 먹어야지. 잘 먹어야 일도 잘하는 법이니까.'
    },
    A2: {
      text: '부장님께 뭐를 먹고 싶은지 물어본다.',
      index1: 7,
      index2: -5,
      hashTage: '4/#부장님딸랑딸랑 #점메추 #출제자의의도를파악하시오',
      result:'이건 기회다. 이런 사소한 게 쌓여서 사내평가가 좌지우지되는거다. 부장님! 뭐 드시겠습니까?'
    },
    A3: {
      text: '사람들 각각 찾아 다니면서 메뉴를 물어본다. 매우 민주적이다.',
      index1: 5,
      index2: -3,
      hashTage: '4/#민주주의 #선택장애On #난못정해',
      result:'모두가 먹고 싶어하는 메뉴가 좋지 않을까. 절대 내가 선택장애가 있어서 그러는 거 아님.'
    },
    A4: {
      text: '항상 자주 먹던 곳, 같은 메뉴로 시킨다. 지겹지만 뭐.',
      index1: 3,
      index2: 0,
      hashTage: '4/#익숙한게좋아 #귀차니즘 #늘먹던걸로주세요',
      result:'에이 귀찮아. 그냥 늘 먹던 거 먹자. 익숙한 게 최고지. '
    },
    A5: {
      text: '선약이 있어서 따로 먹겠다고 얘기한다. 메뉴도 다른 사람에게 맡긴다.',
      index1: -5,
      index2: 7,
      hashTage: '4/#런치는마이웨이 #혼밥최고 #개인주의',
      result:'뭐 진짜 선약이 있었던 건 아니지만... 그냥 혼자 먹는 게 편해서 말이야. '
    },
  },
  {
    Q: '업무시간 내에 계속해서 대리님과 사라지는 나의 동료. 농땡이 피우다니, 흐음...너무 한 거 아닌가?',
    A1: {
      text: '억울하지만 혼자 일해야지 뭐 어쩌겠어.',
      index1: 10,
      index2: -10,
      hashTage: '5/#일은열심히 #화병5분전 #제발열심히',
      result:'굳이 공론화시켜서 분위기 갑분싸 만들기도 싫고. 내가 참자. 하지만 여러가지 감정이 마음 속에서 소용돌이쳐서 머리가 아팠다. 스트레스를 너무 받았나.'
    },
    A2: {
      text: '쉬러 갔으니 나도 잠깐의 힐링타임을 가진다.',
      index1: 0,
      index2: 5,
      hashTage: '5/#공평평등 #힐링타임 #너만쉬냐?',
      result:'뭐 이게 공평한 거 아니겠어? 대리님과 친하다고 동료만 쉴 시간이 많은 것부터가 좀 그래. 나도 쉴 때는 좀 쉬자. 이럴 때 아니면 언제 쉬겠어.'
    },
    A3: {
      text: '이러는 거 불합리하다. 나도 오늘부터 같이 농땡이!',
      index1: -7,
      index2: 7,
      hashTage: '5/#다같이가즈아 #이게맞나 #너흰이러지마라',
      result:'나까지 빠지면 업무가 제대로 진행이 되려나. 뭐, 내 알 바 아니지. 나도 공범이 되겠다.'
    },
    A4: {
      text: '크게 혼잣말을 하며 눈치를 준다. "OO씨, 아니 또 어디갔어? 계속 없네."',
      index1: -7,
      index2: 3,
      hashTage: '5/#눈치챙겨 #믿는도끼로발등찍기 #꼽주는고야?',
      result:'그 일이 있은 후, 동료가 사라지는 일은 많이 적어졌다. 하지만 주변 사람들이 다 나를 피하는 느낌이 든다...'
    },
    A5: {
      text: '동료에게 너무 자주 나가서 일이 느려진다고 솔직하게 얘기한다.',
      index1: -3,
      index2: 7,
      hashTage: '5/#팩트폭격기 #솔직담백 #너랑나랑같은월급',
      result:'솔직히 말했다. 너무한 거 아니냐고. 동료도 미안하다고 사과했다. 하지만 이 어색한 분위기는 어떡할거야 진짜.'
    },
  },
  {
    Q: '점심식사 후, 친하게 지내던 과장님이 커피를 사주신다고 한다.',
    A1: {
      text: '괜찮습니다. 저는 회사가서 믹스커피 먹겠습니다. 매번 사주시는데...',
      index1: 3,
      index2: 0,
      hashTage: '6/#먹여줘도못먹는사람 #소심 #답답이 ',
      result:'매번 얻어먹기도 좀 그래. 오히려 부담스럽달까. 적당한 거리감이 오히려 좋다고 생각해요.'
    },
    A2: {
      text: '프랜차이즈 커피점으로 가서 자바칩 프라푸치노에 디카페인 샷 추가를 하고 휘핑크림을 올린 뒤, 우유 대신 오트밀크로 변경해서 주문한다.',
      index1: -7,
      index2: 10,
      hashTage: '6/#알뜰살뜰다챙기기 #야무지게먹어야지 #커스텀커피',
      result:'내가 제일 좋아하는 거라서 참지 못했다. 하지만 행복하니까 그걸로 됐어. 다음에도 사주세요! 하지만 그런 일은 더 이상 없었다.'
    },
    A3: {
      text: '커피 대신 다른 걸로 안될까요? 제가 커피를 안 마셔서요.',
      index1: -5,
      index2: 7,
      hashTage: '6/#대체품요청 #응애아기입맛 #노노카페인',
      result:'커피는 잘 모르겠다. 딴 사람은 입에 달고 살던데, 그게 그렇게 맛있나? 흐음. 근데 과장님의 눈빛이 미묘하다. 내가 뭐 잘못했나?'
    },
    A4: {
      text: '저는 그럼 아아로 먹겠습니다.',
      index1: 0,
      index2: 3,
      hashTage: '6/#기본으로진행시켜 #근본 #중간만가자',
      result:'이럴 때는 무난무난하게 가야지. 전 아이스 아메리카노 한 잔 먹겠습니다. 이거면 충분해요. 감사합니다.'
    },
    A5: {
      text: '쿠폰은 제 껄로 적립해도 될까요?',
      index1: -10,
      index2: 7,
      hashTage: '6/#포인트도둑 #니돈내쿠 #알뜰살뜰',
      result:'결국 못 참고 말해버렸다. 쿠폰적립은 못 참지. 과장님의 어이없다는 표정을 애써 무시하며 나는 스스로 그렇게 되뇌었다.'
    },
  },
];
export const nightQuestion: Survey[] = [
  {
    Q: '현재 시각 17:50. 곧 퇴근시간이다! 하지만 기쁨도 잠시 상사가 추가 업무를 요청했다. 아...',
    A1: {
      text: '내일의 나에게 맡기고 퇴근한다. 어떻게든 되겠지.',
      index1: -10,
      index2: 7,
      hashTage: '7/#퇴근시간방해금지 #집에갈래 #워라벨',
      result:'집이 나를 부르는 데 안 가면 범죄야. 어쩔 수 없다. 나는 도망치듯 회사를 빠져나왔다. 다음날에 무슨 일이 있을 지 상상도 못 한채.'
    },
    A2: {
      text: '적당히 하다가 상사가 퇴근하면 퇴근한다. 마무리는 내일로!',
      index1: 3,
      index2: -5,
      hashTage: '7/#상사님언제퇴근하세요 #잉싫어요 #눈치게임',
      result:'요령과 눈치만 있다면 회사 생활은 껌이다. 나는 열심히 했다고 모두에게 어필도 하면서 퇴근도 적당하게. 물론 상사가 언제 퇴근하냐에 따라 다르지만.'
    },
    A3: {
      text: '내일의 업무를 위해 일을 마무리하고 늦게 퇴근한다. ',
      index1: 10,
      index2: -12,
      hashTage: '7/#고달픈인생 #퇴사할까 #인생쓰다',
      result:'그래도 어쩌겠어. 일은 해야지. 이러려고 돈 받는 건데. 참 고달픈 내 인생. 힘들다 진짜.'
    },
    A4: {
      text: '약속 있다고 거짓말을 한다.',
      index1: -7,
      index2: 10,
      hashTage: '7/#인생은속고속이는법 #도망가자 #나와의약속',
      result:'난 진짜로 약속이 있었던 거야. 스스로 중얼거리면서 상사한테 거짓말을 했다. 상사는 알겠다고 했지만 왠지 거짓말을 눈치챈 느낌이다.'
    },
    A5: {
      text: '이렇게 된 거 내일 해야될 일까지 해서 야근 수당을 추가로 챙긴다.',
      index1: 7,
      index2: -7,
      hashTage: '7/#실속챙기기 #추가수당최고 #내일은월루왕',
      result:'오케이. 추가 업무 해줄게. 하지만 이왕 하는 거 내일 할 일까지 빨리 끝내고 야근 수당까지 챙기고. 일석이조! ...이긴 한데 밤늦게 회사에 남아있는 게 기분 좋을 리가 없다.'
    },
  },
  {
    Q: '18:00. 퇴근시간인데... 아무도 퇴근을 안한다. 내 할 일은 다 했는데...?',
    A1: {
      text: '상황을 지켜보면서 누군가 퇴근하고 난 뒤 퇴근한다.',
      index1: 3,
      index2: -3,
      hashTage: '8/#눈치게임일 #기회주의자 #사회생활만렙',
      result:'싸늘하다. 가슴에 비수가 날아와 꽂힌다. 하지만 걱정하지 마라. 눈치보는 건 누구보다 빠르니까.'
    },
    A2: {
      text: '상사에게 직접 찾아가 퇴근해도 되는지 물어본다.',
      index1: 0,
      index2: 5,
      hashTage: '8/#당돌한나 #당당한사람 #고양이표정',
      result:'모를 떄는 물어봐야지. 상사한테 다가가 퇴근해도 되는지 물어봤다. 생각보다 쿨하게 너 할 일 다 했으면 퇴근하라는 얘기를 들었다. 감사합니다!'
    },
    A3: {
      text: '정시 퇴근은 원칙이다. 내가 그 선봉장이 되겠다.',
      index1: -5,
      index2: 10,
      hashTage: '8/#칼퇴주의자 #컴퓨터셧다운 #안녕히계세요여러분',
      result:'난 눈치보는 성격이 아니다. 퇴근시간은 18:00 지금이다. 아무도 퇴근을 안 한다면 그 이유가 따로 있겠지. 하지만 나한테 해당하는 이유는 아니다.'
    },
    A4: {
      text: '일단 다른 일을 하면서 대기해본다. 언제 퇴근할 수 있을지 모르겠지만... ',
      index1: 5,
      index2: -7,
      hashTage: '8/#집이저를기다려요 #남의돈받기어렵다 #퇴근시간지켜줘',
      result:'아주 죽을 맛이다. 그냥 퇴근을 할 걸 그랬나. 하지만 회사 분위기가 심상치 않은 거 같으니 일단 얌전히 있는 게 좋을 것 같긴 하다. 언제까지 있어야 할라나...'
    },
    A5: {
      text: '상사에게 업무를 끝냈는데 더 할 일이 있는지 물어본다.',
      index1: 10,
      index2: -10,
      hashTage: '8/#이쁨받기 #추가근무 #돌려말하기 ',
      result:'상사는 기쁜 듯이 날보며 싱긋 미소를 지어보였다. 그럼 할 일이야 얼마든지 있지. 이 말을 들었을 때 나는 보았다. 하늘이 산산조각 무너져 내리는 것을.'
    },
  },
  {
    Q: '이번 주말은 간만에 계획이 산더미다. 밀린 넷플도 보고 데이트도 하고 친구들도 만나야 된다. 근데 갑자기 부장님이 일요일에 등산을 가자고 톡을 했다!',
    A1: {
      text: '난 P가 아니라 J다. 선약이 있어서 못간다고 얘기한다.',
      index1: -5,
      index2: 10,
      hashTage: '9/#파워J #등산은너나하세요 #미리말해주세요 ',
      result:'안 갑니다. 등산을 갈 거였으면 미리 말을 하던가. 물론 그래도 안 가겠지만 그래도 원래 계획과 비교해보고 어떤 걸 고를지 선택할 수 있는 시간이라도 있었을 거다.'
    },
    A2: {
      text: '계획을 포기하고 등산을 같이 간다. ',
      index1: 5,
      index2: -10,
      hashTage: '9/#딸랑이가되볼까 #사회의쓴맛 #전과막걸리',
      result:'주말까지 이어지는 회사생활이라. 이거는 너무한 거 아니냐고 진짜. 근데 어떡해. 거절하기에는 눈치가 너무 보이는 걸. 나한테는 선택권이 없다.'
    },
    A3: {
      text: '계획을 어떻게든 조정한다. 너무 힘들겠지만 등산도 가고 원래 계획도 진행한다.',
      index1: 3,
      index2: -7,
      hashTage: '9/#아이언맨 #철인경기 #몸이여러개였으면',
      result:'결국 난 해냈다. 친구들과의 즐거운 시간도 보냈고 부장님의 추가점수도 땄다. 하지만 내 몸은 무너졌다. 월요일에 나는 몸살난 몸을 이끌고 회사에 출근했다. '
    },
    A4: {
      text: '간다고 한 다음 당일날 아프다고 연기를 한다.',
      index1: -7,
      index2: 7,
      hashTage: '9/#연기파배우 #나를위한거짓말 #가짜환자',
      result:'누가 봐도 수상해 보일만 하다. 간다고 해놓고 당일날 아프다고 하다니. 하지만 대놓고 안 간다고 할 수는 없고 가기는 싫고. 이게 최선이었다. 거짓말이 잘 먹혔을까?'
    },
    A5: {
      text: '안읽씹을 한다. 난 바빠서 그 내용을 보지 못한 것이다.',
      index1: -10,
      index2: 5,
      hashTage: '9/#업무시간외연락금지 #카톡잘안봐요 #애써못본척',
      result:'자네 주말에 카톡 왜 안 읽었나? 월요일에 출근하자마자 부장님이 물어보았다. 가기 싫으면 안 간다고 얘기라도 하지. 팀원들 자네를 얼마나 기다렸는지 아나? 머리 속이 새하얘졌다.'
    },
  },
];
