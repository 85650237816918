import React, { useContext, useState } from 'react';
import '../Style.css';
import gameContext from '../../Provider/gameContext';
export function PhaseImg() {
  const { isPhase, setPhase } = useContext(gameContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const next = () => {
    setTimeout(() => {
      setLoading(true);
    }, 3000);
    setTimeout(() => {
      setPhase(isPhase + 1);
    }, 4000);
  };

  const showImg = (phase: number) => {
    switch (phase) {
      case 1:
        return (
          <div
            className={
              !isLoading
                ? 'Loading_Background_m'
                : 'Loading_Background_m_effect'
            }
          >
            <div className="loading_bar">
              출근시간
              <div className="loading">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div
            className={
              !isLoading
                ? 'Loading_Background_a'
                : 'Loading_Background_a_effect'
            }
          >
            <div className="loading_bar">
              점심시간
              <div className="loading">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        );
      case 7:
        return (
          <div
            className={
              !isLoading
                ? 'Loading_Background_n'
                : 'Loading_Background_n_effect'
            }
          >
            <div className="loading_bar">
              퇴근시간
              <div className="loading">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        );
      default:
        break;
    }
  };
  return (
    <>
      {showImg(isPhase)}
      {next()}
    </>
  );
}

