import '../Style.css';
import gameContext, {
  Type,
  Survey,
  randomizeNumber,
  updateUserData,
} from '../../Provider/gameContext';
import {
  afternoonQuestion,
  morningQuestion,
  nightQuestion,
} from '../../Provider/surveyData';
import {
  shuffledCard,
  shuffledCategory,
  shuffledLetter,
  shuffledPaper,
  shuffledPart,
} from '../../Provider/gameData';
import React, { useContext, useState, useEffect } from 'react';
import { DisplayInfo } from '../../Display';

export function PhaseSurvey() {
  const {
    isUser,
    isState,
    isPhase,
    setPage,
    setState,
    setPop,
    setPhase,
    setPaper,
    setCard,
    setPart,
    setTyping,
    setCategorizing,
    isAnswer,
    setAnswer,
    isDialogue
  } = useContext(gameContext);

  const [question, setQuestion] = useState<Survey[]>([
    {
      Q: '',
      A1: { text: '', index1: 0, index2: 0, hashTage: '',result:'' },
      A2: { text: '', index1: 0, index2: 0, hashTage: '' ,result:''},
      A3: { text: '', index1: 0, index2: 0, hashTage: '' ,result:''},
      A4: { text: '', index1: 0, index2: 0, hashTage: '' ,result:''},
      A5: { text: '', index1: 0, index2: 0, hashTage: '' ,result:''},
    },
  ]);
  useEffect(() => {
    switch (isPhase) {
      case 2:
        setQuestion(morningQuestion);
        break;
      case 5:
        setQuestion(afternoonQuestion);
        break;
      case 8:
        setQuestion(nightQuestion);
        break;
      default:
        break;
    }
  }, [isPhase]);

  const [isEffect, setEffect] = useState<boolean>(false);
  const [isClick, setClick] = useState<boolean>(false);

  const nextPhase = () => {
    if (!isClick) {
      if (isAnswer.text !== '') {
        switch (isUser.type) {
          case Type.white:
            if (isPhase === 2) {
              setPaper(shuffledPaper());
              updateUserData({
                input: {
                  id: isUser.name,
                  accomplishmentRate:isState.index1+isAnswer.index1>100?100:isState.index1+isAnswer.index1<0?0:parseFloat((isState.index1 + isAnswer.index1).toFixed(2)),
                  stressRate:isState.index2+isAnswer.index2>100?100:isState.index2+isAnswer.index2<0?0:parseFloat((isState.index2 - isAnswer.index2).toFixed(2)),
                  tage1: isAnswer.hashTage,
                  phase: isPhase + 1,
                },
              });
            }
            if (isPhase === 5) {
              setTyping(shuffledLetter());
              updateUserData({
                input: {
                  id: isUser.name,
                  accomplishmentRate:isState.index1+isAnswer.index1>100?100:isState.index1+isAnswer.index1<0?0:parseFloat((isState.index1 + isAnswer.index1).toFixed(2)),
                  stressRate:isState.index2+isAnswer.index2>100?100:isState.index2+isAnswer.index2<0?0:parseFloat((isState.index2 - isAnswer.index2).toFixed(2)),

                  tage2: isAnswer.hashTage,
                  phase: isPhase + 1,
                },
              });
            }
            break;
          case Type.blue:
            if (isPhase === 2) {
              setCategorizing(shuffledCategory());
              updateUserData({
                input: {
                  id: isUser.name,
                  accomplishmentRate:isState.index1+isAnswer.index1>100?100:isState.index1+isAnswer.index1<0?0:parseFloat((isState.index1 + isAnswer.index1).toFixed(2)),
                  stressRate:isState.index2+isAnswer.index2>100?100:isState.index2+isAnswer.index2<0?0:parseFloat((isState.index2 - isAnswer.index2).toFixed(2)),

                  tage1: isAnswer.hashTage,
                  phase: isPhase + 1,
                },
              });
            }
            if (isPhase === 5) {
              setPart(shuffledPart(0));
              updateUserData({
                input: {
                  id: isUser.name,
                  accomplishmentRate:isState.index1+isAnswer.index1>100?100:isState.index1+isAnswer.index1<0?0:parseFloat((isState.index1 + isAnswer.index1).toFixed(2)),
                  stressRate:isState.index2+isAnswer.index2>100?100:isState.index2+isAnswer.index2<0?0:parseFloat((isState.index2 - isAnswer.index2).toFixed(2)),

                  tage2: isAnswer.hashTage,
                  phase: isPhase + 1,
                },
              });
            }
            break;
          case Type.pink:
            if (isPhase === 2) {
              updateUserData({
                input: {
                  id: isUser.name,
                  accomplishmentRate:isState.index1+isAnswer.index1>100?100:isState.index1+isAnswer.index1<0?0:parseFloat((isState.index1 + isAnswer.index1).toFixed(2)),
                  stressRate:isState.index2+isAnswer.index2>100?100:isState.index2+isAnswer.index2<0?0:parseFloat((isState.index2 - isAnswer.index2).toFixed(2)),

                  tage1: isAnswer.hashTage,
                  phase: isPhase + 1,
                },
              });
            }
            if (isPhase === 5) {
              setCard(shuffledCard());
              updateUserData({
                input: {
                  id: isUser.name,
                  accomplishmentRate:isState.index1+isAnswer.index1>100?100:isState.index1+isAnswer.index1<0?0:parseFloat((isState.index1 + isAnswer.index1).toFixed(2)),
                  stressRate:isState.index2+isAnswer.index2>100?100:isState.index2+isAnswer.index2<0?0:parseFloat((isState.index2 - isAnswer.index2).toFixed(2)),

                  tage2: isAnswer.hashTage,
                  phase: isPhase + 1,
                },
              });
            }
            break;

          default:
            break;
        }
        setPop('surveyRespond');
        setClick(true);
        setTimeout(() => {
          setEffect(true);
          setState({
            ...isState,
            index1: isState.index1+isAnswer.index1>100?100:isState.index1+isAnswer.index1<0?0:parseFloat((isState.index1 + isAnswer.index1).toFixed(2)),
            index2: isState.index2+isAnswer.index2>100?100:isState.index2+isAnswer.index2<0?0:parseFloat((isState.index2 - isAnswer.index2).toFixed(2)),
          });
        }, 2000);
        setTimeout(() => {
          setPhase(isPhase + 1);
          if (isPhase < 7) {
            setPop('gameExplanation');
          } else {
            updateUserData({
              input: {
                id: isUser.name,
                accomplishmentRate:isState.index1+isAnswer.index1>100?100:isState.index1+isAnswer.index1<0?0:parseFloat((isState.index1 + isAnswer.index1).toFixed(2)),
                stressRate:isState.index2+isAnswer.index2>100?100:isState.index2+isAnswer.index2<0?0:parseFloat((isState.index2 - isAnswer.index2).toFixed(2)),
                tage3: isAnswer.hashTage,
                phase: isPhase + 1,
              },
            });
            setPage('Result');
          }
        }, 3000);
      } else {
        setPop('noAnswer');
      }
    }
  };

  const buttonAction = (value: number) => {
    switch (value) {
      case 0:
        setAnswer({
          text: question[isDialogue].A1.text,
          index1: question[isDialogue].A1.index1,
          index2: question[isDialogue].A1.index2,
          hashTage: question[isDialogue].A1.hashTage,
          result:question[isDialogue].A1.result
        });
        break;
      case 1:
        setAnswer({
          text: question[isDialogue].A2.text,
          index1: question[isDialogue].A2.index1,
          index2: question[isDialogue].A2.index2,
          hashTage: question[isDialogue].A2.hashTage,
          result:question[isDialogue].A2.result
        });
        break;
      case 2:
        setAnswer({
          text: question[isDialogue].A3.text,
          index1: question[isDialogue].A3.index1,
          index2: question[isDialogue].A3.index2,
          hashTage: question[isDialogue].A3.hashTage,
          result:question[isDialogue].A3.result
        });
        break;
      case 3:
        setAnswer({
          text: question[isDialogue].A4.text,
          index1: question[isDialogue].A4.index1,
          index2: question[isDialogue].A4.index2,
          hashTage: question[isDialogue].A4.hashTage,
          result:question[isDialogue].A4.result
        });
        break;
      case 4:
        setAnswer({
          text: question[isDialogue].A5.text,
          index1: question[isDialogue].A5.index1,
          index2: question[isDialogue].A5.index2,
          hashTage: question[isDialogue].A5.hashTage,
          result:question[isDialogue].A5.result
        });
        break;
      default:
        break;
    }
  };

  const displayQuestion = () => {
    switch (isPhase) {
      case 2:
        return (
          <div className={!isEffect ? 'Survey_box' : 'Survey_box_effect'}>
            <div className="Survey_text">
              <div className="Survey_alert">출근 이벤트 발생!</div>
              <div className="Survey_question">
                Q.{morningQuestion[isDialogue].Q}
              </div>
              <button className="Survey_answer" onClick={() => buttonAction(0)}>
                1. {morningQuestion[isDialogue].A1.text}
              </button>
              <button className="Survey_answer" onClick={() => buttonAction(1)}>
                2. {morningQuestion[isDialogue].A2.text}
              </button>
              <button className="Survey_answer" onClick={() => buttonAction(2)}>
                3. {morningQuestion[isDialogue].A3.text}
              </button>
              <button className="Survey_answer" onClick={() => buttonAction(3)}>
                4. {morningQuestion[isDialogue].A4.text}
              </button>
              <button className="Survey_answer" onClick={() => buttonAction(4)}>
                5. {morningQuestion[isDialogue].A5.text}
              </button>
              <button className="Survey_answer_btn" onClick={nextPhase}>
                답변하기
              </button>
            </div>
          </div>
        );
      case 5:
        return (
          <div className={!isEffect ? 'Survey_box' : 'Survey_box_effect'}>
            <div className="Survey_text">
              <div className="Survey_alert">점심 이벤트 발생!</div>
              <div className="Survey_question">
                Q.{afternoonQuestion[isDialogue].Q}
              </div>
              <button className="Survey_answer" onClick={() => buttonAction(0)}>
                1. {afternoonQuestion[isDialogue].A1.text}
              </button>
              <button className="Survey_answer" onClick={() => buttonAction(1)}>
                2. {afternoonQuestion[isDialogue].A2.text}
              </button>
              <button className="Survey_answer" onClick={() => buttonAction(2)}>
                3. {afternoonQuestion[isDialogue].A3.text}
              </button>
              <button className="Survey_answer" onClick={() => buttonAction(3)}>
                4. {afternoonQuestion[isDialogue].A4.text}
              </button>
              <button className="Survey_answer" onClick={() => buttonAction(4)}>
                5. {afternoonQuestion[isDialogue].A5.text}
              </button>
              <button className="Survey_answer_btn" onClick={nextPhase}>
                답변하기
              </button>
            </div>
          </div>
        );
      case 8:
        return (
          <div className={!isEffect ? 'Survey_box' : 'Survey_box_effect'}>
            <div className="Survey_text">
              <div className="Survey_alert">퇴근 이벤트 발생!</div>
              <div className="Survey_question">
                Q.{nightQuestion[isDialogue].Q}
              </div>
              <button className="Survey_answer" onClick={() => buttonAction(0)}>
                1. {nightQuestion[isDialogue].A1.text}
              </button>
              <button className="Survey_answer" onClick={() => buttonAction(1)}>
                2. {nightQuestion[isDialogue].A2.text}
              </button>
              <button className="Survey_answer" onClick={() => buttonAction(2)}>
                3. {nightQuestion[isDialogue].A3.text}
              </button>
              <button className="Survey_answer" onClick={() => buttonAction(3)}>
                4. {nightQuestion[isDialogue].A4.text}
              </button>
              <button className="Survey_answer" onClick={() => buttonAction(4)}>
                5. {nightQuestion[isDialogue].A5.text}
              </button>
              <button className="Survey_answer_btn" onClick={nextPhase}>
                답변하기
              </button>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <div className="PhaseBackground">
      {DisplayInfo()}
      {displayQuestion()}
    </div>
  );
}

