import { useContext } from 'react';
import gameContext, {
  randomizeNumber,
  updateUserData,
  Type,
} from '../Provider/gameContext';
import './Style.css';

export function DisplayInfo() {
  const { isUser, isState } = useContext(gameContext);
  return (
    <div className="Info">
      <div className="Info_img">
        <div className="Info_img_face">
          <div className="Info_img_acc">{DisplayAcc()}</div>
          {DisplayFace()}
        </div>
      </div>
      <div className="Info_data">
        <div className="Info_data_line">
          <div className="Info_data_subject">이 름:</div>
          <div className="Info_data_text">{isUser.name}</div>
        </div>
        <div className="Info_data_line">
          <div className="Info_data_subject">분 류:</div>
          <div className="Info_data_text">{isUser.type}</div>
        </div>
        <div className="Info_data_line">
          <div className="Info_data_subject">성 취 도:</div>
          <div className="Info_data_text">
            <div className="Info_data_index_bar">
              <div
                className="Info_data_index_color_bar"
                style={{
                  width: `${isState.index1}%`,
                  backgroundColor: '#4285f4',
                }}
              ></div>
              <div className="Info_data_index_num">
                {isState.index1.toFixed(0)}%
              </div>
            </div>
          </div>
        </div>
        <div className="Info_data_line">
          <div className="Info_data_subject">스 트 레 스:</div>
          <div className="Info_data_text">
            <div className="Info_data_index_bar">
              <div
                className="Info_data_index_color_bar"
                style={{
                  width: `${isState.index2}%`,
                  backgroundColor: '#d20000',
                }}
              ></div>
              <div className="Info_data_index_num">
                {isState.index2.toFixed(0)}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DisplayEx(value: Type, phase: number) {
  switch (value) {
    case Type.blue:
      if (phase === 3) {
        return <div className="ex_b1" />;
      }
      if (phase === 6) {
        return <div className="ex_b2" />;
      }
      break;
    case Type.pink:
      if (phase === 3) {
        return <div className="ex_p1" />;
      }
      if (phase === 6) {
        return <div className="ex_p2" />;
      }
      break;
    default:
      if (phase === 3) {
        return <div className="ex_w1" />;
      }
      if (phase === 6) {
        return <div className="ex_w2" />;
      }
      break;
  }
}

export function DisplayCard(value: number) {
  switch (value) {
    case 1:
      return <div className="Card1" />;
    case 2:
      return <div className="Card2" />;
    case 3:
      return <div className="Card3" />;
    case 4:
      return <div className="Card4" />;
    case 5:
      return <div className="Card5" />;
    case 6:
      return <div className="Card6" />;
    case 7:
      return <div className="Card7" />;
    default:
      return <div className="Card8" />;
  }
}

export function DisplayFace() {
  const { isUser } = useContext(gameContext);
  const isFace = (isUser.face % 4).toString().replace(/[^0-9]/g, '');
  switch (isUser.type) {
    case Type.blue:
      return <div className={`Face_b${isFace}`}></div>;
    case Type.pink:
      return <div className={`Face_p${isFace}`}></div>;
    default:
      return <div className={`Face_w${isFace}`}></div>;
  }
}

export function DisplayAcc() {
  const { isUser } = useContext(gameContext);
  const isACC = (isUser.acc % 4).toString().replace(/[^0-9]/g, '');
  switch (isUser.type) {
    case Type.blue:
      return <div className={`Acc_b${isACC}`}></div>;
    case Type.pink:
      return <div className={`Acc_p${isACC}`}></div>;
    default:
      return <div className={`Acc_w${isACC}`}></div>;
  }
}

export function DisplayCategorize(value: number) {
  switch (value) {
    case 0:
      return <div className="Categorize_object1" />;
    case 1:
      return <div className="Categorize_object2" />;
    case 2:
      return <div className="Categorize_object3" />;
    case 3:
      return <div className="Categorize_object4" />;
    default:
      break;
  }
}

export function DisplayMissile(value: number) {
  if (value === 1) return <div className="Game_avoid_launch_missile"></div>;
  else return <div className="Game_avoid_launch_not"></div>;
}

export function DisplayTime(time: number) {
  const {
    setStart,
    setPop,
    isUser,
    isState,
    isPhase,
    isRound,
    setRound,
  } = useContext(gameContext);
  if (time > 0 && time <= 80000) {
    return (
      <div className="Game_time">
        <div className="Game_timebar">
          <div className="Game_timer" />
          <div
            className="Game_timecolor_bar"
            style={{ width: `${(time / 80000) * 100}%` }}
          />
        </div>
      </div>
    );
  } else if (time > 80000) {
    return (
      <>
        <div className="Game_ready">
          <div className="Game_time">
            {time > 83000 ? (
              <span>Round {isRound}</span>
            ) : (
              <span>{Math.floor((time / 1000) % 80) + 1}</span>
            )}
          </div>
        </div>
        <div className="Game_time">
          <div className="Game_timebar">
            <div className="Game_timer" />
            <div className="Game_timecolor_bar" style={{ width: '99.9%' }} />
          </div>
        </div>
      </>
    );
  } else {
    try {
      updateUserData({
        input: {
          id: isUser.name,
          accomplishmentRate: parseFloat(isState.index1.toFixed(2)),
          stressRate: parseFloat(isState.index2.toFixed(2)),
          challengeRate: isState.index3,
          failRate: Math.floor(isState.index4),
          phase: isPhase + 1,
        },
      });
    } catch (error) {
      console.log(error);
    }
    setRound(1);
    setStart(false);
    setPop('gameClear');
    return (
      <div className="Game_time">
        <div className="Game_timebar">
          <div className="Game_timer" />
          <div className="Game_timecolor_bar" style={{ width: `0%` }} />
        </div>
      </div>
    );
  }
}
export function DisplayIndex(index: number) {
  if (index >= 90 && index <= 100) {
    return '역대급으로 높음.';
  } else if (index >= 70 && index < 90) {
    return '꽤 높은 수치. ';
  } else if (index >= 50 && index < 70) {
    return '약간 높음.';
  } else if (index >= 30 && index < 50) {
    return '살짝 낮음.';
  } else if (index >= 10 && index < 30) {
    return '많이 낮음. ';
  } else {
    return '역대급으로 낮음.';
  }
}

export function DisplayIndex1ment(index: number) {
  if (index >= 90 && index <= 100) {
    return '엄청난 업무처리능력. 완벽한 에이스, 그 자체. 이 정도면 어디서든 모셔가겠군요.';
  } else if (index >= 75 && index < 90) {
    return '브라보! 못하는게 없는 다재다능 만능재주꾼. 훌륭합니다!';
  } else if (index >= 60 && index < 75) {
    return '어디가서 일 못한다 소리는 안 듣겠네요! 앞으로도 이 정도만 유지한다면 좋을 듯 합니다.';
  } else if (index >= 40 && index < 60) {
    return '맡겨진 일에는 최선을 다하지만 만족스러운 결과가 잘 나오지 않아 속상하겠지만 괜찮아요, 내일은 내일의 해가 뜰 거예요';
  } else if (index >= 20 && index < 40) {
    return '처음하는 일인데 어떻게 처음부터 잘해? 시간이 지나고 노하우가 생기면 멋진 커리어인이 될 수 있을거예요.';
  } else {
    return '이런...일이 나와 잘 안 맞을 수 있어요... 하지만 언제나 기회는 있는 법. 포기는 금물!';
  }
}

export function DisplayIndex2ment(index: number) {
  if (index >= 90 && index <= 100) {
    return '정신이 붕괴 일보직전입니다. 육체도 곧 무너질겁니다. 일도 중요하지만 본인도 챙겼어야죠.';
  } else if (index >= 70 && index < 90) {
    return '아무도 건들지 마. 멀리 달리기 위해 자기자신을 채찍질 해왔지만, 지금은 그 덕에 정신에 상처가 나버렸는걸요.';
  } else if (index >= 50 && index < 70) {
    return '업무도 중요하지만 스트레스 관리도 중요합니다. 스트레스 해소법을 찾아봅시다.';
  } else if (index >= 30 && index < 50) {
    return '스트레스를 받았지만 심각하지 않아요. 밸런스가 중요한 법이죠!';
  } else if (index >= 10 && index < 30) {
    return '압도적인 스트레스 관리. 대단합니다. 이 정도면 매일매일을 행복하게 보낼 수 있겠어요. 부럽습니다.';
  } else {
    return '현실을 초월한 현자 등장. 세상이 너무 아름다워요. 보기 좋지만 적당한 스트레스는 미래를 위해 필요하답니다.';
  }
}

export function DisplayIndex3ment(index: number) {
  if (index >= 0 && index <= 2) {
    return '안정적으로 플레이하셨네요! 역시 본인 일만 하고 칼퇴하는 게 최고죠.';
  } else if (index > 2 && index <= 4) {
    return '적당적당히. 어쩔 수 없는 경우에는 추가업무를 하겠지만 강제하지는 말라구요!';
  } else if (index > 4 && index <= 8) {
    return '도전정신이 훌륭합니다! 그래요. 이 정도는 해야죠. 더욱 나은 미래를 위해!';
  } else {
    return '워커홀릭. 하이리스크에는 하이리턴이 따라오는 법이죠. 인생은 한방!';
  }
}

export function DisplayIndex4ment(index: number) {
  if (index >= 0 && index <= 10) {
    return '완벽합니다! 실수가 거의 없었네요. 어떻게...하신거죠?';
  } else if (index > 10 && index <= 25) {
    return '평균적인 수치입니다. 적당한 실수는 본인을 돌아보는 기회가 될 수 있죠.';
  } else if (index > 25 && index <= 40) {
    return '가끔 일을 까먹거나 일처리를 허둥지둥 덤벙거릴 수도 있겠군요. 당황하지 마세요!';
  } else {
    return '이 정도면...할말하않. 앞으로 실수를 차근차근 줄여봅시다.';
  }
}

export function DisplayIndex3star(index: number) {
  if (index >= 0 && index <= 2) {
    return '☆☆☆';
  } else if (index > 2 && index <= 4) {
    return '★☆☆';
  } else if (index > 4 && index <= 8) {
    return '★★☆';
  } else {
    return '★★★';
  }
}

export function DisplayIndex4star(index: number) {
  if (index >= 0 && index <= 10) {
    return '★★★';
  } else if (index > 10 && index <= 25) {
    return '★★☆';
  } else if (index > 25 && index <= 40) {
    return '★☆☆';
  } else {
    return '☆☆☆';
  }
}

export function DisplayIndex1(index: number) {
  if (index >= 90 && index <= 100) {
    return '부장';
  } else if (index >= 75 && index < 90) {
    return '차장';
  } else if (index >= 60 && index < 75) {
    return '과장';
  } else if (index >= 40 && index < 60) {
    return '대리';
  } else if (index >= 20 && index < 40) {
    return '사원';
  } else {
    return '인턴';
  }
}
export function DisplayIndex2(index: number) {
  if (index >= 90 && index <= 100) {
    return '곧 퇴사할';
  } else if (index >= 70 && index < 90) {
    return '이직 생각 중인';
  } else if (index >= 50 && index < 70) {
    return '출근하기 싫은';
  } else if (index >= 30 && index < 50) {
    return '인내심이 뛰어난';
  } else if (index >= 10 && index < 30) {
    return '매사에 긍정적인';
  } else {
    return '하루하루 행복한';
  }
}

export function Displayinstar(type: string) {
  switch (type) {
    case '1':
      return <div className="endimg-01" />;
    case '2':
      return <div className="endimg-02" />;
    case '3':
      return <div className="endimg-03" />;
    case '4':
      return <div className="endimg-04" />;
    case '5':
      return <div className="endimg-05" />;
    case '6':
      return <div className="endimg-06" />;
    case '7':
      return <div className="endimg-07" />;
    case '8':
      return <div className="endimg-08" />;
    default:
      return <div className="endimg-09" />;
  }
}
