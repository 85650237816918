/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getIfthenUser = /* GraphQL */ `
  query GetIfthenUser($id: ID!) {
    getIfthenUser(id: $id) {
      id
      ifthenAccessKey {
        id
        activated
        date
        ifthenUser {
          id
          accomplishmentRate
          stressRate
          challengeRate
          failRate
          type
          phase
          acc
          face
          tage1
          tage2
          tage3
          createdAt
          updatedAt
          ifthenUserIfthenAccessKeyId
          __typename
        }
        createdAt
        updatedAt
        ifthenAccessKeyIfthenUserId
        __typename
      }
      accomplishmentRate
      stressRate
      challengeRate
      failRate
      type
      phase
      acc
      face
      tage1
      tage2
      tage3
      createdAt
      updatedAt
      ifthenUserIfthenAccessKeyId
      __typename
    }
  }
`;
export const listIfthenUsers = /* GraphQL */ `
  query ListIfthenUsers(
    $filter: ModelIfthenUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIfthenUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ifthenAccessKey {
          id
          activated
          date
          createdAt
          updatedAt
          ifthenAccessKeyIfthenUserId
          __typename
        }
        accomplishmentRate
        stressRate
        challengeRate
        failRate
        type
        phase
        acc
        face
        tage1
        tage2
        tage3
        createdAt
        updatedAt
        ifthenUserIfthenAccessKeyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIfthenAccessKey = /* GraphQL */ `
  query GetIfthenAccessKey($id: ID!) {
    getIfthenAccessKey(id: $id) {
      id
      activated
      date
      ifthenUser {
        id
        ifthenAccessKey {
          id
          activated
          date
          createdAt
          updatedAt
          ifthenAccessKeyIfthenUserId
          __typename
        }
        accomplishmentRate
        stressRate
        challengeRate
        failRate
        type
        phase
        acc
        face
        tage1
        tage2
        tage3
        createdAt
        updatedAt
        ifthenUserIfthenAccessKeyId
        __typename
      }
      createdAt
      updatedAt
      ifthenAccessKeyIfthenUserId
      __typename
    }
  }
`;
export const listIfthenAccessKeys = /* GraphQL */ `
  query ListIfthenAccessKeys(
    $filter: ModelIfthenAccessKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIfthenAccessKeys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activated
        date
        ifthenUser {
          id
          accomplishmentRate
          stressRate
          challengeRate
          failRate
          type
          phase
          acc
          face
          tage1
          tage2
          tage3
          createdAt
          updatedAt
          ifthenUserIfthenAccessKeyId
          __typename
        }
        createdAt
        updatedAt
        ifthenAccessKeyIfthenUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
