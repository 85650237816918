import { useContext, useEffect, useState } from 'react';
import gameContext, {randomizeNumber } from '../Provider/gameContext';
import {
  shuffledPaper,
  shuffledCard,
  shuffledPart,
  isMLine1,
  isMLine2,
  isMLine3,
  useInterval,
  shuffledLetter,
  shuffledCategory,
  shuffledLetter2,
  shuffledCategory2,
  missileLine1,
  missileLine3,
  missileLine2,
} from '../Provider/gameData';
import {
  DisplayCard,
  DisplayCategorize,
  DisplayInfo,
  DisplayMissile,
  DisplayTime,
} from '../Display';
import './Style.css';


export function Game1() {
  const {
    isStart,
    isPaper,
    setData,
    setState,
    isState,
    setPaper,
    setRound,
    setStart,
    isRound,
    setPop,
  } = useContext(gameContext);
  const [s, setS] = useState<boolean>(false);
  const [isTime, setTime] = useState<number>(85000);
  const [isExtraSwitch, setExtraSwitch] = useState<boolean>(false);
  const [isBaseTime, setBaseTime] = useState<number>(0);
  const [isCount, setCount] = useState<number>(0)
  const [isWrong, setWrong] = useState<boolean>(false)

  const timeValue = 85000;

  useEffect(() => {
    setTimeout(() => {
      if (s) {
        setS(false);
        if (!isExtraSwitch && isStart) {
          const date = new Date();
          setBaseTime(Math.floor(date.getTime()));
          setExtraSwitch(true);
        } else if (isStart) {
          const date = new Date();
          setTime(
            timeValue +
            (isBaseTime - date.getTime()) * ((3 * isRound) / (isRound + 2))
          );
        }
      } else {
        setS(true);
      }
    }, 50);
  }, [
    s,
    isBaseTime,
    isExtraSwitch,
    isStart,
    setBaseTime,
    setExtraSwitch,
    isRound,
  ]);

  useEffect(() => {
    setData([isState.index1.toString(), isState.index2.toString()]);
  }, []);

  const buttonClick = (value: number) => {
    if (isTime < 80000) {
      if (value === isPaper[0].answer) {
        setState({
          ...isState,
          index1:
            isState.index1 + 2.67 > 100
              ? 100
              : isState.index1 + 2.67,
          index2: isState.index2 + 0.66 > 100 ? 100 : isState.index2 + 0.66,
        });
        if (isCount >= 2) {
          setCount(0);
          setPaper(shuffledPaper());
          setExtraSwitch(false);
          setStart(false);
          setRound(isRound + 1);
          setPop('continue_round');
        } else {
          isPaper.shift()
          setCount(isCount + 1);
        }
      } else {
        setWrong(true)
        setState({
          ...isState,
          index1:
            isState.index1 - 2 < 0
              ? 0
              : isState.index1 - 2,
          index2: isState.index2 + 2 > 100 ? 100 : isState.index2 + 2,
          index4: isState.index4 + 1
        });
        setTimeout(() => {
          setWrong(false)
        }, 100)
      }
    }
  };

  return (
    <div className="PhaseBackground">
      {DisplayInfo()}
      <div className="Game">
        {DisplayTime(isTime)}
        <div className="Paper_txt">
          {isPaper[0].text1.map((item) => {
            return <div dangerouslySetInnerHTML={{ __html: item }} />;
          })}
        </div>
        <div className="Paper_txt">
          {isPaper[0].text2.map((item) => {
            return <div dangerouslySetInnerHTML={{ __html: item }} />;
          })}
        </div>

        <div className="Game_btn_controller">
          {isWrong ? <div className="Game_btn" style={{ backgroundColor: 'red' }} onClick={() => buttonClick(0)}>
            없다
          </div> : <div className="Game_btn" onClick={() => buttonClick(0)}>
            없다
          </div>}
          {isWrong ? <div className="Game_btn" style={{ backgroundColor: 'red' }} onClick={() => buttonClick(1)}>
            1개
          </div> : <div className="Game_btn" onClick={() => buttonClick(1)}>
            1개
          </div>}        {isWrong ? <div className="Game_btn" style={{ backgroundColor: 'red' }} onClick={() => buttonClick(2)}>
            2개
          </div> : <div className="Game_btn" onClick={() => buttonClick(2)}>
            2개
          </div>}        {isWrong ? <div className="Game_btn" style={{ backgroundColor: 'red' }} onClick={() => buttonClick(3)}>
            3개
          </div> : <div className="Game_btn" onClick={() => buttonClick(3)}>
            3개
          </div>}
        </div>
      </div>
    </div>
  );
}//완료
export function Game2() {
  const { isTyping, setTyping, setData, setState, isState, isStart, isRound, setStart, setRound, setPop } =
    useContext(gameContext);

  const [isA, setA] = useState<boolean>(false);
  const [isWrong, setWrong] = useState<boolean>(false);
  const [isExtraSwitch, setExtraSwitch] = useState<boolean>(false);
  const [isBaseTime, setBaseTime] = useState<number>(0);
  const [isTime, setTime] = useState<number>(85000);

  const timeValue = 85000;

  useEffect(() => {
    setData([isState.index1.toString(), isState.index2.toString()]);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (isA) {
        setA(false);
        if (!isExtraSwitch && isStart) {
          const date = new Date();
          setBaseTime(Math.floor(date.getTime()));
          setExtraSwitch(true);
        } else if (isStart) {
          const date = new Date();
          setTime(
            timeValue +
            (isBaseTime - date.getTime()) * ((3 * isRound) / (isRound + 2))
          );
        }
      } else {
        setA(true);
      }
    }, 50);
  }, [
    isA,
    isBaseTime,
    isExtraSwitch,
    isStart,
    setBaseTime,
    setExtraSwitch,
    isRound,
  ]);

  const stateSetter = (value: boolean) => {
    if (value) setState({
      ...isState,
      index1: isState.index1 + 0.11 > 100 ? 100 : isState.index1 + 0.11,
      index2: isState.index2 + 0.03 > 100 ? 100 : isState.index2 + 0.03,
    })
    else setState({
      ...isState,
      index1: isState.index1 - 1.01 < 0 ? 0 : isState.index1 - 1.01,
      index2: isState.index2 + 1 > 100 ? 100 : isState.index2 + 1,
    })
  }

  const checkNewArray = () => {
    if (isTyping.length <= 1) {
      setExtraSwitch(false);
      setStart(false);
      setRound(isRound + 1);
      setPop('continue_round');
      if (isRound < 2) setTyping(shuffledLetter());
      else setTyping(shuffledLetter2());
    }
  };

  const buttonClick = (value: number) => {
    if (isTime < 80000) {
      switch (value) {
        case 0:
          if (isTyping[0] === '□') {
            checkNewArray();
            stateSetter(true);
            isTyping.shift();
          } else {
            setState({
              ...isState,
              index4: isState.index4 + 1
            })
            setWrong(true);
            stateSetter(false);
            setTimeout(() => {
              setWrong(false);
            }, 100);
          }
          break;
        case 1:
          if (isTyping[0] === '△') {
            checkNewArray();
            stateSetter(true);
            isTyping.shift();
          } else {
            setState({
              ...isState,
              index4: isState.index4 + 1
            })
            setWrong(true);
            stateSetter(false);
            setTimeout(() => {
              setWrong(false);
            }, 100);
          }
          break;
        case 2:
          if (isTyping[0] === '○') {
            checkNewArray();
            stateSetter(true);
            isTyping.shift();
          } else {
            setState({
              ...isState,
              index4: isState.index4 + 1
            })
            setWrong(true);
            stateSetter(false);
            setTimeout(() => {
              setWrong(false);
            }, 100);
          }
          break;
        case 3:
          if (isTyping[0] === '◇') {
            checkNewArray();
            stateSetter(true);
            isTyping.shift();
          } else {
            setState({
              ...isState,
              index4: isState.index4 + 1
            })
            setWrong(true);
            stateSetter(false);
            setTimeout(() => {
              setWrong(false);
            }, 100);
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="PhaseBackground">
      {DisplayInfo()}
      <div className="Gamew2">
        {DisplayTime(isTime)}
        <div className="Typing_display_Box">
          {isWrong ? (
            <div className="Typing_display_wrong">
              <div className="Typing_display_inside">{isTyping[0]}</div>
              <div className="Typing_display_inside">{isTyping[1]}</div>
              <div className="Typing_display_inside">{isTyping[2]}</div>
              <div className="Typing_display_inside">{isTyping[3]}</div>
            </div>
          ) : (
            <div className="Typing_display">
              <div className='Typing_display_insideColor'>{isTyping[0]}</div>
              <div className="Typing_display_inside">{isTyping[1]}</div>
              <div className="Typing_display_inside">{isTyping[2]}</div>
              <div className="Typing_display_inside">{isTyping[3]}</div>
            </div>
          )}
        </div>
        {isTyping.length}개 남았습니다!
        <div className="Game_btn_controller">
          <div className="Game_btn" onClick={() => buttonClick(0)}>
            □
          </div>
          <div className="Game_btn" onClick={() => buttonClick(1)}>
            △
          </div>
          <div className="Game_btn" onClick={() => buttonClick(2)}>
            ○
          </div>
          {isRound <= 2 ? <div className="Game_btn" style={{ backgroundColor: 'gray' }}>
            ◇
          </div> : <div className="Game_btn" onClick={() => buttonClick(3)}>
            ◇
          </div>}
        </div>
      </div>
    </div>
  );
}//완료
export function Game3() {
  const { isCategorizing, setCategorizing, setState, setData, isState, isStart, setStart, setRound, isRound, setPop } =
    useContext(gameContext);

  const [isA, setA] = useState<boolean>(false);
  const [isWrong, setWrong] = useState<boolean>(false);
  const [isExtraSwitch, setExtraSwitch] = useState<boolean>(false);
  const [isBaseTime, setBaseTime] = useState<number>(0);
  const [isTime, setTime] = useState<number>(85000);

  const timeValue = 85000;

  useEffect(() => {
    setData([isState.index1.toString(), isState.index2.toString()]);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (isA) {
        setA(false);
        if (!isExtraSwitch && isStart) {
          const date = new Date();
          setBaseTime(Math.floor(date.getTime()));
          setExtraSwitch(true);
        } else if (isStart) {
          const date = new Date();
          setTime(
            timeValue +
            (isBaseTime - date.getTime()) * ((3 * isRound) / (isRound + 2))
          );
        }
      } else {
        setA(true);
      }
    }, 50);
  }, [
    isA,
    isBaseTime,
    isExtraSwitch,
    isStart,
    setBaseTime,
    setExtraSwitch,
    isRound,
  ]);

  const buttonAction = (isRight: boolean) => {
    if (isTime < 80000) {
      if (isRight) {
        if (isCategorizing[0] === 0 || isCategorizing[0] === 1) {
          checkNewArray();
          stateSetter(true);
          isCategorizing.shift();
        } else {
          setWrong(true);
          stateSetter(false);
          setTimeout(() => {
            setWrong(false);
          }, 150);
        }
      } else {
        if (isCategorizing[0] === 2 || isCategorizing[0] === 3) {
          checkNewArray();
          stateSetter(true);
          isCategorizing.shift();
        } else {
          setWrong(true);
          stateSetter(false);
          setTimeout(() => {
            setWrong(false);
          }, 150);
        }
      }
    }
  };

  const checkNewArray = () => {
    if (isCategorizing.length <= 1) {
      setExtraSwitch(false)
      setStart(false)
      setRound(isRound + 1)
      setPop('continue_round')
      if (isRound < 2) setCategorizing(shuffledCategory())
      else setCategorizing(shuffledCategory2());
    }
  };

  const stateSetter = (value: boolean) => {
    if (value)
      setState({
        ...isState,
        index1: isState.index1 + 0.09 > 100 ? 100 : isState.index1 + 0.09,
        index2: isState.index2 + 0.02 > 100 ? 100 : isState.index2 + 0.02,

      });
    else
      setState({
        ...isState,
        index1: isState.index1 - 0.77 < 0 ? 0 : isState.index1 - 0.77,
        index2: isState.index2 + 1.07 > 100 ? 100 : isState.index2 + 1.07,
        index4: isState.index4 + 1
      });
  };
  return (
    <div className="PhaseBackground">
      {DisplayInfo()}
      <div className="Game">
        {DisplayTime(isTime)}
        <div className="Categorize_display_alert">
          {isCategorizing.length}개 남았습니다!
        </div>
        <div className="Game_colum_background">
          <div className="Game_btn_box" onClick={() => buttonAction(false)}>
            <p>불량제품</p>
            <button className="Game_arrow_Lbtn" />
          </div>
          {isWrong ?
            <div className="Categorize_display_Red">
              <div className="Categorize_object">
                {DisplayCategorize(isCategorizing[3])}
              </div>
              <div className="Categorize_object">
                {DisplayCategorize(isCategorizing[2])}
              </div>
              <div className="Categorize_object">
                {DisplayCategorize(isCategorizing[1])}
              </div>
              <div className="Categorize_object">
                {DisplayCategorize(isCategorizing[0])}
              </div>
            </div> :
            <div className="Categorize_display_Box">
              <div className="Categorize_object">
                {DisplayCategorize(isCategorizing[3])}
              </div>
              <div className="Categorize_object">
                {DisplayCategorize(isCategorizing[2])}
              </div>
              <div className="Categorize_object">
                {DisplayCategorize(isCategorizing[1])}
              </div>
              <div className="Categorize_object">
                {DisplayCategorize(isCategorizing[0])}
              </div>
            </div>}

          <div className="Game_btn_box" onClick={() => buttonAction(true)}>
            <p>정상제품</p>
            <button className="Game_arrow_Rbtn" />
          </div>
        </div>
      </div>
    </div>
  );
}//완료

export function Game4() {
  const { isStart, setPart, isPart, setState, isState, setData, isRound, setStart, setRound, setPop } =
    useContext(gameContext);
  const defaultOrder: Array<number[]> = [[1, 2, 3, 4], [3, 5, 7, 9], [1, 4, 5, 8], [2, 3, 5, 6], [4, 6, 7, 9]];
  const [s, setS] = useState<boolean>(false);
  const [isTime, setTime] = useState<number>(85000);
  const [isOrder, setOrder] = useState<Array<number>>(defaultOrder[0]);
  const [isClick, setClick] = useState<boolean>(true);
  const [isAssembly, setAssembly] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });
  const [isBaseTime, setBaseTime] = useState<number>(0);
  const [extraSwitch, setExtraSwitch] = useState<boolean>(false);
  const [isCount, setCount] = useState<number>(0);
  const [isWrong, setWrong] = useState<boolean>(false)
  const timeValue = 85000;

  useEffect(() => {
    setData([isState.index1.toString(), isState.index2.toString()]);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (s) {
        setS(false);
        if (!extraSwitch && isStart) {
          const date = new Date();
          setBaseTime(Math.floor(date.getTime()));
          setExtraSwitch(true);
        } else if (isStart) {
          const date = new Date();
          setTime(timeValue + (isBaseTime - date.getTime()) * ((3 * isRound) / (isRound + 2)));
        }
      } else {
        setS(true);
      }
    }, 50);
  }, [
    s,
    isBaseTime,
    extraSwitch,
    isStart,
    setBaseTime,
    setExtraSwitch,
    isRound,
  ]);

  const transForm = (value: number, RandomNumber: number) => {
    switch (RandomNumber) {
      case 10:
        if (value === 1) return 1;
        else if (value === 2) return 2;
        else if (value === 3) return 3;
        else return 4;
      case 24:
        if (value === 3) return 1;
        else if (value === 5) return 2;
        else if (value === 7) return 3;
        else return 4;
      case 18:
        if (value === 1) return 1;
        else if (value === 4) return 2;
        else if (value === 5) return 3;
        else return 4;
      case 16:
        if (value === 2) return 1;
        else if (value === 3) return 2;
        else if (value === 5) return 3;
        else return 4;
      case 26:
        if (value === 4) return 1;
        else if (value === 6) return 2;
        else if (value === 7) return 3;
        else return 4;
      default:
        return 0
    }
  }

  const assemblyPart = (value: number) => {//이거 안나왔을 때 눌러도 똑같은거 아님?
    if (isOrder[0] === value && isClick && isTime < 80000) {
      isOrder.shift();
      setAssembly({
        ...isAssembly,
        [transForm(value, isPart[0] + isPart[1] + isPart[2] + isPart[3])]: true,
      });
    } else {
      setWrong(true)
      setState({
        ...isState,
        index1: isState.index1 - 1.34 < 0 ? 0 : isState.index1 - 1.34,
        index2: isState.index2 + 1.33 > 100 ? 100 : isState.index2 + 1.33,
        index4: isState.index4 + 1
      });
      setTimeout(() => {
        setWrong(false)
      }, 100)
    }
    if (isOrder.length === 0 && isClick) {
      setState({
        ...isState,
        index1: isState.index1 + 0.67 > 100 ? 100 : isState.index1 + 0.67,
        index2: isState.index2 + 0.16 > 100 ? 100 : isState.index2 + 0.16,
      });
      if (isCount >= 11) {
        setCount(0)
        setExtraSwitch(false)
        setStart(false)
        setRound(isRound + 1)
        setPop('continue_round')
      }
      else setCount(isCount + 1)
      setClick(false);
      setTimeout(() => {
        setClick(true);
        if (isRound <= 2) {
          setPart(shuffledPart(0));
          setOrder(defaultOrder[0]);
        }
        else {
          const inputRandomNumber = randomizeNumber(5)
          setPart(shuffledPart(inputRandomNumber))
          setOrder(defaultOrder[inputRandomNumber])
        }
        setAssembly({
          1: false,
          2: false,
          3: false,
          4: false,
        });
      }, 1000);
    }
  };

  return (
    <div className="PhaseBackground">
      {DisplayInfo()}
      <div className="Game">
        {DisplayTime(isTime)}
        {12 - isCount}개 남았습니다!
        <div className="Game_Part">
          {isAssembly[1] && isAssembly[2] && isAssembly[3] && isAssembly[4] && (
            <div className="Game_Part_finish">완성!</div>
          )}
          {isAssembly[1] && <div className="Game_Part1" />}
          {isAssembly[2] && <div className="Game_Part2" />}
          {isAssembly[3] && <div className="Game_Part3" />}
          {isAssembly[4] && <div className="Game_Part4" />}
        </div>
        <div className="Game_btn_controller">
          {(isClick && isWrong) &&
            isPart.map((item: number) => {
              return (
                <div className="Game_btn" style={{ backgroundColor: 'red' }} onClick={() => assemblyPart(item)}>
                  {item}
                </div>
              );
            })}
          {(isClick && !isWrong) &&
            isPart.map((item: number) => {
              return (
                <div className="Game_btn" onClick={() => assemblyPart(item)}>
                  {item}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
export function Game5() {
  const { isStart, setState, isState, isRound, setStart, setPop, setRound, setData } = useContext(gameContext);
  const [s, setS] = useState<boolean>(false);
  const [isTime, setTime] = useState<number>(85000);
  const [isBaseTime, setBaseTime] = useState<number>(0);
  const [extraSwitch, setExtraSwitch] = useState<boolean>(false);
  const [isAttack, setAttack] = useState<boolean>(false);
  const [isLine, setLine] = useState<number>(1);
  const [isCount, setCount] = useState<number>(0);
  const [isTerm, setTerm] = useState<number>(350)


  const [isCLine, setCLine] = useState<number[][]>([
    [0, 0, 0, 0, 0, 0],
    [1, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0],
  ]);

  const timeValue = 85000;

  useEffect(() => {
    setData([isState.index1.toString(), isState.index2.toString()]);
  }, []);

  useInterval(() => {
    const next: number = randomizeNumber(isRound <= 2 ? 17 : 23)
    if (isTime > 0 && isTime <= 80000 && isStart) {
      if ((isCount > 60 && isRound <= 2) || isCount > 70) {
        setCount(0)
        setExtraSwitch(false)
        setTerm(400 - isRound * 50)
        setState({
          ...isState,
          index1: isState.index1 + 8 > 100 ? 100 : isState.index1 + 8,
          index2: isState.index2 + 2 > 100 ? 100 : isState.index2 + 2,
        });
        setStart(false)
        setRound(isRound + 1)
        setPop('continue_round')
      }
      else setCount(isCount + 1)
      isMLine1.shift();
      isMLine2.shift();
      isMLine3.shift();
      if (isMLine1[0] === 1 && isLine === 0) {
        setState({
          ...isState,
          index1: isState.index1 - 1 < 0 ? 0 : isState.index1 - 1,
          index2: isState.index2 + 1 > 100 ? 100 : isState.index2 + 1,
          index4: isState.index4 + 1
        });
        setCount(0);
        setAttack(true);
      } else if (isMLine2[0] === 1 && isLine === 1) {
        setState({
          ...isState,
          index1: isState.index1 - 1 < 0 ? 0 : isState.index1 - 1,
          index2: isState.index2 + 1 > 100 ? 100 : isState.index2 + 1,
          index4: isState.index4 + 1
        });
        setCount(0);
        setAttack(true);
      } else if (isMLine3[0] === 1 && isLine === 2) {
        setState({
          ...isState,
          index1: isState.index1 - 1 < 0 ? 0 : isState.index1 - 1,
          index2: isState.index2 + 1 > 100 ? 100 : isState.index2 + 1,
          index4: isState.index4 + 1
        });
        setCount(0);
        setAttack(true);
      } else {
        setAttack(false);
      }
      if (isMLine1.length < 10) isMLine1.push(missileLine1[next][0], missileLine1[next][1], missileLine1[next][2], missileLine1[next][3])
      if (isMLine2.length < 10) isMLine2.push(missileLine2[next][0], missileLine2[next][1], missileLine2[next][2], missileLine2[next][3])
      if (isMLine3.length < 10) isMLine3.push(missileLine3[next][0], missileLine3[next][1], missileLine3[next][2], missileLine3[next][3])
    }
  }, isTerm);

  useEffect(() => {
    setTimeout(() => {
      if (s) {
        setS(false);
        if (!extraSwitch && isStart) {
          const date = new Date();
          setBaseTime(Math.floor(date.getTime()));
          setExtraSwitch(true);
        } else if (isStart) {
          const date = new Date();
          setTime(
            timeValue +
            (isBaseTime - date.getTime()) * ((3 * isRound) / (isRound + 2))
          );
        }
      } else {
        setS(true);
      }
    }, 50);
  }, [
    s,
    extraSwitch,
    setBaseTime,
    isStart,
    setExtraSwitch,
    isBaseTime,
    isRound,
  ]);

  const moveCharacter = (value: string, index: number) => {
    if (value === 'right' && index === 1) {
      setCLine([
        [0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0],
        [1, 0, 0, 0, 0, 0],
      ]);

      setLine(2);
    }
    if (value === 'right' && index === 0) {
      setCLine([
        [0, 0, 0, 0, 0, 0],
        [1, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0],
      ]);

      setLine(1);
    }
    if (value === 'left' && index === 1) {
      setCLine([
        [1, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0],
      ]);

      setLine(0);
    }
    if (value === 'left' && index === 2) {
      setCLine([
        [0, 0, 0, 0, 0, 0],
        [1, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0],
      ]);

      setLine(1);
    }
  };

  return (
    <div className="PhaseBackground">
      {DisplayInfo()}
      <div className="Game">
        {DisplayTime(isTime)}
        <div className="Game_colum_background">
          <div className="Game_btn_box" onClick={() => moveCharacter('left', isLine)}>
            <button
              className="Game_arrow_Lbtn"

            />
          </div>
          <div className="Game_avoid_launch">
            <div className="Game_avoid_character_line">
              {isCLine[0]
                .slice(0)
                .reverse()
                .map((item: number, index: number) => {
                  if (item === 1) {
                    return (
                      <>
                        {isAttack ? (
                          <div className="Game_avoid_characterA"></div>
                        ) : (
                          <div className="Game_avoid_character"></div>
                        )}
                      </>
                    );
                  } else return <div></div>;
                })}
              <div className="Game_avoid_launch_line">
                <div className="Game_avoid_v1" />
                {DisplayMissile(isMLine1[4])}
                {DisplayMissile(isMLine1[3])}
                {DisplayMissile(isMLine1[2])}
                {DisplayMissile(isMLine1[1])}
                {DisplayMissile(isMLine1[0])}
              </div>
            </div>
            <div className="Game_avoid_character_line">
              {isCLine[1]
                .slice(0)
                .reverse()
                .map((item: number, index: number) => {
                  if (item === 1) {
                    return (
                      <>
                        {isAttack ? (
                          <div className="Game_avoid_characterA"></div>
                        ) : (
                          <div className="Game_avoid_character"></div>
                        )}
                      </>
                    );
                  } else return <div></div>;
                })}
              <div className="Game_avoid_launch_line">
                <div className="Game_avoid_v2" />
                {DisplayMissile(isMLine2[4])}
                {DisplayMissile(isMLine2[3])}
                {DisplayMissile(isMLine2[2])}
                {DisplayMissile(isMLine2[1])}
                {DisplayMissile(isMLine2[0])}
              </div>
            </div>
            <div className="Game_avoid_character_line">
              {isCLine[2]
                .slice(0)
                .reverse()
                .map((item: number, index: number) => {
                  if (item === 1) {
                    return (
                      <>
                        {isAttack ? (
                          <div className="Game_avoid_characterA"></div>
                        ) : (
                          <div className="Game_avoid_character"></div>
                        )}
                      </>
                    );
                  } else return <div></div>;
                })}
              <div className="Game_avoid_launch_line">
                <div className="Game_avoid_v3" />
                {DisplayMissile(isMLine3[4])}
                {DisplayMissile(isMLine3[3])}
                {DisplayMissile(isMLine3[2])}
                {DisplayMissile(isMLine3[1])}
                {DisplayMissile(isMLine3[0])}
              </div>
            </div>
          </div>
          <div className="Game_btn_box" onClick={() => moveCharacter('right', isLine)}>
            <button
              className="Game_arrow_Rbtn"

            />
          </div>
        </div>
      </div>
    </div>
  );
}
export function Game6() {
  const { isStart, setState, isState, isCard, setCard, isRound, setRound, setStart, setPop, setData } =
    useContext(gameContext);
  const [s, setS] = useState<boolean>(false);
  const [isTime, setTime] = useState<number>(85000);
  const [isClear, setClear] = useState<number>(0);
  const [isIndex, setIndex] = useState<number>(-1);
  const [isFlip, setFlip] = useState<number>(0);
  const [isBaseTime, setBaseTime] = useState<number>(0);
  const [extraSwitch, setExtraSwitch] = useState<boolean>(false);

  const timeValue = 85000;

  useEffect(() => {
    setData([isState.index1.toString(), isState.index2.toString()])
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (s) {
        setS(false);
        if (!extraSwitch && isStart) {
          const date = new Date();
          setBaseTime(Math.floor(date.getTime()));
          setExtraSwitch(true);
        } else if (isStart) {
          const date = new Date();
          setTime(
            timeValue +
            (isBaseTime - date.getTime()) * ((4 * isRound) / (isRound + 3))
          );
        }
      } else {
        setS(true);
      }
    }, 50);
  }, [
    s,
    extraSwitch,
    setBaseTime,
    isStart,
    setExtraSwitch,
    isBaseTime,
    isRound,
  ]);

  const checkAnswer = (indexs: number) => {
    if (isFlip < 2) {
      if (isFlip === 0) {
        setFlip(1);
        setCard(
          isCard.map((item, index) =>
            index === indexs
              ? {
                value: item.value,
                open: 'true',
              }
              : item
          )
        );
        setIndex(indexs);
      } else if (isFlip === 1) {
        setFlip(2);
        const temp = isCard.map((item, index) =>
          index === indexs
            ? {
              value: item.value,
              open: 'clear',
            }
            : item
        );
        setCard(temp);
        if (isCard[indexs].value !== isCard[isIndex].value) {
          setTimeout(() => {
            setCard(
              isCard.map((item, index) =>
                index === indexs || index === isIndex
                  ? {
                    value: item.value,
                    open: 'false',
                  }
                  : item
              )
            );
            setFlip(0);
          }, 500);
          setState({
            ...isState,
            index1: isState.index1 - 0.8 < 0 ? 0 : isState.index1 - 0.8,
            index2: isState.index2 + 0.8 > 100 ? 100 : isState.index2+0.8,
            index4: isState.index4 + 0.4
          });
          setIndex(-1);
        } else {
          setTimeout(() => {
            setCard(
              temp.map((item, index) =>
                item.open === 'true'
                  ? { value: item.value, open: 'clear' }
                  : item
              )
            );
            setState({
              ...isState,
              index1: isState.index1 + 1 > 100 ? 100 : isState.index1 + 1,
              index2: isState.index2 + 0.25 > 100 ? 100 : isState.index2 + 0.25,
            });
            setClear(isClear + 2);
            if (isClear === 14 && isTime > 0) {
              setExtraSwitch(false)
              setStart(false)
              setRound(isRound + 1)
              setPop('continue_round')
              setClear(0);
              setCard(shuffledCard());
            }
            setFlip(0);
          }, 500);
          setIndex(-1);
        }
      }
    }
  };

  return (
    <div className="PhaseBackground">
      {DisplayInfo()}
      <div className="Game">
        {DisplayTime(isTime)}

        <div className="Game_card">
          {isTime > 81000

            ? isCard.map((item: any) => {
              return (
                <div className="Game_cards">
                  <div className="card-side-back">
                    {DisplayCard(item.value)}
                  </div>
                </div>
              );
            })

            : isCard.map((item: any, index: number) => {
              return (
                <div className="Game_cards">
                  {item.open === 'true' || item.open === 'clear' ? (
                    <div className="card-side-back">
                      {DisplayCard(item.value)}
                    </div>
                  ) : (
                    <div
                      className="card-side-front"
                      onClick={() => checkAnswer(index)}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
